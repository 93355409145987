import React from 'react'
import HeaderImage from '../assets/executive.png'
import Navigation from '../navbar'
import Asmah from '../assets/leadership/asmah.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const Management = () => {
        navigate("/robertasmah")
    }

    const ExecuManagement = () => {
        navigate("/leadership")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Executive Management
            </h1>

            <div class="grid phone:grid-cols-2 md:grid-cols-3 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Robert Asmah</p>
                        <p class="text-black font-fira text-[20px] md:mx-20 phone:mx-10">General Manager, Technical Services</p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            Mr. Robert Asmah is a Quality Engineer with over 29 years’ experience in the oil and Gas Industry. He has extensive knowledge in consulting on Quality Management system and Risk Based inspection for organization and individuals.</p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            Before joining GNGC, he served with institutions such as; British Petroleum (BP), Applus RTD Netherlands, MODEC International Tema Oil Refinery (TOR) and Ghana Cylinder Manufacturing Company.</p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] mmd:mx-20 phone:mx-10 text-justify leading-snug">
                            He is Currently the General Manager, Technical Service at Ghana National Gas Company.</p>  <br />
                        
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            Mr. Asmah is a quality Management System Auditor, a practitioner of the Chartered Quality Institute, a professional member of the American Society for Quality and a Risk Based Inspection Professional. He also holds an MBA from the University of Stirling.</p>
                    </div>
                </div>
            </div>
            <br />

            <Footer />
        </>
    )

}

export default Leadership;