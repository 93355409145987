import React from 'react'
import HeaderImage from '../assets/Board.png'
import Navigation from '../navbar'
import Asmah from '../assets/board/Asiaa.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const ExecuManagement = () => {
        navigate("/board")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Board of Directors
            </h1>

            <div class="grid phone:grid-cols-2 md:grid-cols-3 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Nana Adwoa Asiaa Benneh Beyeeman I  </p>
                        <p class="text-black font-fira text-[20px] md:mx-20 phone:mx-10">Board Member</p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            Nana Adwoa Asiaa Benneh Beyeeman 1 was born on 19th October 1943. She received her undergraduate degree from the University of Ghana and obtained a post graduate degree in Management at the University of Manchester UK.                         
                        </p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            She was employed at Bank of Ghana in 1968 and held a number of appointments including Director and Head of Human Resources Department and Adviser to the Governor of Bank of Ghana. Nana Adwoa Asiaa Benneh Beyeeman 1 was Chairman at IFS Financial Services Ltd. She was also a Member of the Board of Directors at GCB Bank Ltd. Nana Adwoa Asiaa Benneh Beyeeman 1 was previously employed as a Principal by National Banking College.                        </p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            She has attended a number of programmes, including “Human Resources Development’’ in Vienna; “Human Resource Strategy and Globalization’’ at the Golden Gate University in San Francisco, USA; “Human Resources Development’’ at the University of Pittsburgh, USA; “Manpower Planning’’ at the Institute of Management Studies, University of Sussex, Brighton, UK; ‘’Women in Senior Management’’ at the Royal Institute of Public Administration (RIPA).                     </p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            Her publications include:<br />
                            •	 ‘Human Resource Development at Bank of Ghana’ Published by World Bank 1998.<br />
                            •	 ‘Human Resource Management and Women in Financial Sector’ Published in an Edited Book Experiences in Capacity Building of Ghanaian Women ‘Asempa Publisher, Christian Council of Ghana – 2001
                        </p>
                        <br/>
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        She became the Principal of the National Banking College from 2001 – 2008. In 2015, she received a “Distinguished Finance Education Award” by National Women Finance Magazine. She is also the Nkosuohemaa of her hometown, Wirenkyiren Amanfrom in the Eastern Region and is currently the Dwantoahemaa of Akyem Abuakwa in the Eastern Region and goes by the Stool name Nana Adwoa Asiaa Benneh Beyeeman I. Her hobbies include walking, reading and listening to music.
                        </p>  
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        She is currently a Board Member of Ghana National Gas Company and serves as the Chairman for the HR & Governance Committee. She is also a Member of the Corporate Social Responsibility Committee and the Finance & Commercial Committee.                        </p> 
                        <br />
                    </div>
                </div>
            </div>
            <br />


            <Footer />
        </>
    )

}

export default Leadership;