import React from 'react'
import HeaderImage from '../assets/Board.png'
import Navigation from '../navbar'
import Asmah from '../assets/board/Awulae.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const ExecuManagement = () => {
        navigate("/board")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Board of Directors
            </h1>

            <div class="grid phone:grid-cols-2 md:grid-cols-3 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Awulae Amihere Kpanyinli III</p>
                        <p class="text-black font-fira text-[20px] md:mx-20 phone:mx-10">Board Member</p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Awulae Amihere Kpanyinli III is known in private life as Mark Rockson and a Rural Development Consultant by profession. He is the Paramount Chief of Eastern Nzema and Vice President of the Western Regional House of Chiefs.                         </p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Awulae Amihere Kpanyinli III is a Member of the following Committees<br/>
                        •	Member, Judicial Committee, Western Regional House of Chiefs <br/>
•	Member, Standing Committee, Western Regional House of Chiefs <br/>
•	Member, Development and Governance Committee, NHC<br/>

                        
                                                </p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        He was a Member of the Finance and Staff Committee, National House of Chiefs. 
                        </p>
                        <br/>
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Awulae Amihere Kpanyinli III as part of education has a BA in Economics and International Development, a Diploma in Entrepreneurship, (Kansas), and a Diploma in Agricultural Engineering
                        </p>
                        <br />
                        
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        His interests include Education, Health and Agriculture.
                        </p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                      Awulae Amihere Kpanyinli III was appointed a Director at Ghana National Gas Company in the year 2016. He is the Chairman of the Audit Committee and Member of the Corporate Social Responsibility Committee and the Technical Committee.
                        </p>
                        <br />
                    </div>
                </div>
            </div>
            <br />

            <Footer />
        </>
    )

}

export default Leadership;