import React from 'react';
import HeaderImage from '../assets/SHE 2.jpg';
import Environment from '../assets/The Environment.png';
import HR from '../assets/Work at Ghana Gas.png';
import Footer from '../footer'
import Navigation from '../navbar'
import { useNavigate } from 'react-router-dom';
import Document from '../documents/Env Policy.pdf';

function Profile() {
  let navigate = useNavigate();

  const Env = () => {
    navigate("/theenvironment")
  }

  const HRP = () => {
    navigate("/hrpolicy")
  }
  return (
    <>
      <Navigation />
      <div className="relative">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
          src={HeaderImage}
          alt="Ghana Gas GPP"
        />
      </div>
      <div>
        <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
          Health Safety and Environment Policy Statement  </h1>

        <h1 className="font-fjalla text-gasblue text-xl phone:p-4 xs:mt-8 xs:ml-10 sm:text-2xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-3xl lg:m-10 xl:text-3xl xl:mx-[270px] xl:mt-[100px]">
          Occupational Health & Safety Policy Statement    </h1>




        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
          GHANA NATIONAL GAS COMPANY (GHANA GAS) is committed to protecting the health and safety of its workforce and others affected by the Company’s business activities in gathering, processing, transporting and marketing of natural gas resources in Ghana.    </h1>

        <h1 className=" font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
          In line with this commitment, we shall: <br /><br />
          Provide safe and healthy working conditions for the prevention of work-related injury and/or ill health and eliminate hazards and reduce OH&S risks during operations. <br /><br />
          Ensure consultation and participation of workers and where they exist worker representatives. <br /><br />
          Take appropriate measures designed to comply with applicable laws, HSE regulations and licensing requirements. <br /><br />
          Set goals, objectives and targets which motivate each member of the workforce to strive for continual improvement in our safety performance. <br /><br />
          Periodically evaluate the effectiveness and suitability of the systems and framework put in place for the attainment of our health and safety goals, evaluate resources available and to ensure continual improvement of the system.<br /><br />
          Top management shall ensure that the responsibilities and authorities for relevant roles within the OH&S management system are assigned and communicated at all levels within the organization. <br /><br />
          In all work activities we shall be guided by the following key principles on safety which Managers and Supervisors shall reinforce:<br />
        </h1>

        <h1 className=" font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•Do it safely or not at all.<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•Doing it right the first time.<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;•When in doubt find out.
        </h1>

        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          This Policy shall be clearly communicated to the workforce and all persons working under the control of Ghana Gas with the intent that they are made aware of their individual health and safety obligations and the Policy shall be made available to interested parties where necessary.
        </h1>

        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          The Chief Executive Officer (CEO) has the overall authority and responsibility of ensuring that adequate resources are provided to enhance the smooth implementation of this Health and Safety Policy.    </h1>

        <h1 className="font-fjalla text-gasblue text-xl phone:p-4 xs:mt-8 xs:ml-10 sm:text-2xl sm:mt-20 sm:ml-10  md:text-2xl md:mt-20 md:ml-10 lg:text-3xl lg:m-10 xl:text-3xl xl:mx-[270px] xl:mt-[100px]">
          Environmental Policy Statement
        </h1>
        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:text-[28px] xl:mx-[270px]">
          GHANA NATIONAL GAS COMPANY (GHANA GAS) is committed to conducting its
          business activities of gathering, processing, transporting and marketing of natural gas resources
          in a manner that ensures protection of the environment
        </h1>

        <h1 className=" font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-6 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
          In line with this commitment, we shall:
        </h1>
        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-6 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          •	 Minimize impacts of our operations on the environment through the prevention of
          pollution, sustainable resource use, mitigation of climate change and protection of
          ecosystems.    </h1>
        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-6 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          •	Fulfil our compliance obligations and environmental requirements.</h1>
        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-6 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          •	Promote effective waste management strategies by reducing, re-using, recycling and ensuring safe deposal of waste. </h1>

        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-6 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          •	Operate and maintain our natural gas infrastructure in a manner that minimizes emissions to air and discharges to water and land. </h1>
        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-6 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          •	Engage positively with interested parties and conduct business in a socially responsible manner.  </h1>
        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-6 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          •	Periodically evaluate the effectiveness and suitability of the environmental management system to enhance our environmental performance and ensure continual improvement.</h1>
        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-6 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          •	Set goals, objectives and targets which motivate each member of the workforce to contribute to our environmental performance. </h1>
        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-6 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          •	Ensure that responsibilities and authorities for relevant roles within the environmental management system are assigned and communicated at all levels within the organization. </h1>

        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-6 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          This Policy shall be clearly communicated to the workforce and all persons working under the control of Ghana Gas with the intent that they are made aware of their individual environmental management obligations, and the Policy shall also be made available to interested parties where necessary.</h1>
        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-6 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          The Chief Executive Officer (CEO) has the overall authority and responsibility of ensuring that adequate resources are provided to enhance the smooth implementation of this Environmental Policy.</h1>

        <div className="relative hover:cursor-pointer" onClick={Env}>
          <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black bg-opacity-50 flex">
            <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
              The Environment
            </h1>
          </div>
          <img
            className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
            src={Environment}
            alt="Ghana Gas GPP"
          />
        </div>

        <div className="relative hover:cursor-pointer" onClick={HRP}>
          <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black bg-opacity-50 flex">
            <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
              HR Philosophy
            </h1>
          </div>
          <img
            className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
            src={HR}
            alt="Ghana Gas GPP"
          />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Profile;
