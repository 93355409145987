import React from 'react';
import Navigation from '../navbar'
import { useNavigate } from 'react-router-dom';
import OurPeople from '../ourpeople'
import Carousel from '../Carousel';
import News from '../News'
import CEOs from '../assets/CEO.png'
import Glance from '../glance';
import CSI from '../csi'
import NextPage from '../nextpage';
import Footer from '../footer'
import SocialMedia from '../socialmedia';
import MobileView from '../ourpeople-mobile'
import CEOMessage from '../ceomessage-mobile'


function Homepage() {
  let navigate = useNavigate();
  const Mandate = () => {
    navigate("/mandate")
  }

  const Mentorship = () => {
    navigate("/mentorship")
  }
  const Policies = () => {
    navigate("/hsepolicy")
  }
  const Objectives = () => {
    navigate("/corporateobjectives")
  }

  const History = () => {
    navigate("/history")
  }
  const Scholarship = () => {
    navigate("/scholarship")
  }




  return (
    <>

      <Navigation />
      <Carousel />
      <Glance />
      <div class="phone:hidden md:hidden lg:block">
        <OurPeople />
      </div>

      <div class="phone:block md:block lg:hidden">
        <MobileView/>
      </div>

      <div class="flex flex-col 4K-screen:mt-[65rem] small-laptop:mt-[36rem] large-screen:mt-[56rem] xl:mt-[46rem] hp-desktop:mt-[48rem] macbook-air:mt-[43rem]">
        <img src={CEOs} className='large-screen:mt-[6rem] small-laptop:mt-[5rem] hp-desktop:mt-[6rem] macbook-air:mt-[1rem] phone:hidden md:hidden lg:block' />

      <div class="phone:block md:block lg:hidden ">
        <CEOMessage/>
      </div> 
       
        <News />

        <div className="grid grid-cols-1 md:grid-cols-1 sm:grid-cols-1 handheld:grid-cols-1 handheld:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:mt-[4.5rem] xl:mb-[1.5rem] small-laptop:mt-[3rem] tablet:mt-[2rem] phone:mt-[2rem]">
  <div className="bg-gasblue text-white text-center sm:py-[100px] md:py-[90px] lg:py-[110px] xl:py-[200px] font-fjalla text-[96px] phone:text-[60px] leading-tight tablet:text-[80px]">
    Discover<br /><span className='text-[#F96E20]'>#GhanaGas</span>
  </div>
  <div className="bg-gasblue p-4 text-white sm:py-[100px] md:py-[20px] lg:py-[110px] xl:py-[230px] space-y-5">
    <div className='flex flex-col space-y-[1rem] sm:flex-row sm:space-y-0 sm:space-x-[1rem]'>
      <button class="transition ease-in-out rounded-[5px] border-[1px] border-white hover:bg-white duration-700 hover:text-black bg-gasblue px-4 text-white font-fira text-[32px]" onClick={Mentorship}>Mentorship</button>
      <button class="transition ease-in-out rounded-[5px] border-[1px] border-white hover:bg-white duration-700 hover:text-black bg-gasblue px-4 text-white font-fira text-[32px]" onClick={Policies}>Policies</button>
    </div>

    <div className='flex flex-col space-y-[1rem] sm:flex-row sm:space-y-0 sm:space-x-[1rem]'>
      <button class="transition ease-in-out rounded-[5px] border-[1px] border-white hover:bg-white duration-700 hover:text-black bg-gasblue px-4 text-white font-fira text-[32px]" onClick={Scholarship}>Scholarship</button>
      <button class="transition ease-in-out rounded-[5px] border-[1px] border-white hover:bg-white duration-700 hover:text-black bg-gasblue px-4 text-white font-fira text-[32px]" onClick={Objectives}>Objectives</button>
    </div>

    <div className='flex flex-col space-y-[1rem] sm:flex-row sm:space-y-0 sm:space-x-[1rem]'>
      <button class="transition ease-in-out rounded-[5px] border-[1px] border-white hover:bg-white duration-700 hover:text-black bg-gasblue px-4 text-white font-fira text-[32px]" onClick={Mandate}>Mandate</button>
      <button class="transition ease-in-out rounded-[5px] border-[1px] border-white hover:bg-white duration-700 hover:text-black bg-gasblue px-4 text-white font-fira text-[32px]" onClick={History}>History</button>
    </div>
  </div>
</div>

      </div>
      
      <CSI />
      <div className='flex row py-20'>
        <SocialMedia />
      </div>
      <NextPage />
      <Footer />
    </>
  )
}

export default Homepage;