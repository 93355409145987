import React from 'react';
import HeaderImage from '../assets/The Environment.png';
import Objectives from '../assets/Corporate Objectives.png';
import HSE from '../assets/SHE 2.jpg';
import Footer from '../footer'
import Navigation from '../navbar'
import { useNavigate } from 'react-router-dom';

function Profile() {
  let navigate = useNavigate();

  const HSEPolicy = () => {
    navigate("/hsepolicy")
  }

  const CorpV = () => {
    navigate("/corporateobjectives")
  }
  return (
    <>
    <Navigation/>
    <div className="relative">
      <div className="absolute inset-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
          
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
        src={HeaderImage}
        alt="Ghana Gas GPP"
      />
    </div>
    <div>

    <h1 className="font-fjalla phone:p-4 phone:text-justify text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    The Environment
    </h1>

    <h1 className="font-fira text-dark phone:p-4 phone:text-justify text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:text-[28px] xl:mx-[270px]">
    We live by our core value of respect for the environment which means striving to cause no harm to the people and the environment. Ghana Gas has adopted suitable globally acknowledged environmentally friendly technologies in its pipe laying activities so as to preserve our water bodies and vegetation.     </h1>

    <h1 className="font-fira text-dark phone:p-4 phone:text-justify text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
    Appropriate measures have been put in place to ensure that the management and disposal of our industrial waste conforms to the standards of Environmental Protection Agency (EPA). We strive to reduce operational noise by enclosing all machines used in the construction and operational processes. We are heavily reliant on the environment and believe that our business would not survive without a sustainable environment.    </h1>


    <div className="relative hover:cursor-pointer" onClick={CorpV}>
      <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
            Corporate Objectives
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
        src={Objectives}
        alt="Ghana Gas GPP"
      />
    </div>

    <div className="relative hover:cursor-pointer" onClick={HSEPolicy}>
      <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
          HSE Policy
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
        src={HSE}
        alt="Ghana Gas GPP"
      />
    </div>
<Footer/>
    </div>
    </>
  );
}

export default Profile;
