import React from "react";
import Navigation from '../navbar'
import Education from '../educationprojects'
import Sports from '../sportsprojects'
import Health from '../healthprojects'
import Mobile from './csi-mobile'
import Footer from '../footer'
import { useNavigate } from "react-router-dom";

export default function Community(){
    let navigate = useNavigate();

    const Commu = () => {
      navigate("/socialinvestment")
    }
    return(
<>
<Navigation />

<div className="flex flex-col xl:mb-5">
<h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-5 lg:justify-start lg:text-justify xl:text-[28px] xl:mx-[10px]">
Ghana Gas has embarked on these educational, sports and sanitational projects across the country.<br/>
<button class="transition ease-in-out rounded-[5px] border-[1px] border-gasblue hover:bg-white duration-700 hover:text-black bg-gasblue px-4 text-white font-fira md:text-[28px] phone:text-[15px] xl:mx-[2px]" onClick={Commu}>Click here to read more </button>
 </h1>
 <div class="phone:hidden md:block lg:block"><Education/></div>
<div class="phone:hidden md:block lg:block"><Sports/></div>
<div class="phone:hidden md:block lg:block"><Health /></div>
<div class="phone:block md:hidden lg:hidden"><Mobile /></div>


</div>

<Footer />

</>

    )
} 