import React, { useState } from 'react';
import NewsOne from './assets/news-1.png';
import NewsTwo from './assets/news-2.png';
import NewsThree from './assets/news-3.png';
import NewsFour from './assets/news-4.png';
import {
  TbSquareArrowRightFilled,
  TbSquareArrowLeftFilled,
} from "react-icons/tb";

const images = [
  { id: 1, src: NewsOne, title: 'Ghana Gas donates 2 million cedis to victims of Appiatse explosion' },
  { id: 2, src: NewsTwo, title: 'Ghana Gas celebrates 10 years anniversary' },
  { id: 3, src: NewsThree, title: 'Ghana National Gas Company grabs four prestigious awards' },
  { id: 4, src: NewsFour, title: 'Ghana Gas supports Olympian' },
  { id: 5, src: NewsOne, title: 'Ghana Gas donates 2 million cedis to victims of Appiatse explosion' },
  { id: 6, src: NewsTwo, title: 'Ghana Gas celebrates 10 years anniversary' },
];

const App = () => {
  const [visibleImages, setVisibleImages] = useState([
    images[0],
    images[1],
    images[2],
    images[3],
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const showNextImages = () => {
    // Calculate the next set of images in a loop
    const nextIndex = (currentIndex + 1) % images.length;
    const nextImages = [
      images[nextIndex],
      images[(nextIndex + 1) % images.length],
      images[(nextIndex + 2) % images.length],
      images[(nextIndex + 3) % images.length],
    ];

    setVisibleImages(nextImages);
    setCurrentIndex(nextIndex);
  };

  const showPreviousImages = () => {
    // Calculate the previous set of images in a loop
    const previousIndex = (currentIndex - 1 + images.length) % images.length;
    const previousImages = [
      images[previousIndex],
      images[(previousIndex + 1) % images.length],
      images[(previousIndex + 2) % images.length],
      images[(previousIndex + 3) % images.length],
    ];

    setVisibleImages(previousImages);
    setCurrentIndex(previousIndex);
  };

  return (
    <div className="flex flex-col mt-3 small-laptop:mt-1">
      <div className='flex flex-row justify-between items-center'>
        <h1 className='font-fira text-2xl pl-4 small-laptop:text-3xl'>Latest News</h1>
        <div className='flex pr-4'>
          <TbSquareArrowLeftFilled
            onClick={showNextImages}
            className='h-7 w-7 cursor-pointer'
          />
          <TbSquareArrowRightFilled onClick={showPreviousImages} className='h-7 w-7 cursor-pointer' />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row w-full md:space-x-8 p-4">
        {visibleImages.map((image) => (
          <div key={image.id} className="flex flex-col sm:w-1/4">
            <img src={image.src} alt={`Image ${image.id}`} />
            <h1 className='font-fira text-lg sm:text-xl'>
              {image.title}
            </h1>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
