import React from 'react'
import HeaderImage from '../assets/Board.png'
import Navigation from '../navbar'
import Asmah from '../assets/board/Sumani.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const ExecuManagement = () => {
        navigate("/board")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Board of Directors
            </h1>

            <div class="grid phone:grid-cols-2 md:grid-cols-3 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Stephen Sumani Nayina  </p>
                        <p class="text-black font-fira text-[20px] md:mx-20 phone:mx-10">Board Member</p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Stephen Sumani Nayina graduated from University of Cape Coast with a degree in Education and then proceeded to obtain Graduate Certificate in Public Administration from Ghana Institute of Management and Public Administration.                        </p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        He started out as an English and History teacher at Kanton Training College from September 1972 to August 1974, he was also Head of Economics Department of Business Secondary School in Tamale and taught Economics and History as well from September 1974 to August 1976. 
                        <br/><br/>
                        Nayina then took up an appointment with the State Insurance Corporation from 1976 to September 1976 to November 1983.
                        </p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        In 1983, Nayina took up a Contract teaching appointment with The Libyan Government until June, 1986 when he returned to Ghana after the expiration of the Libyan Government contract.                        </p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        He then went back to teach Economics at Ghana Secondary School, Tamale from September 1986 to August 1987. He was thereafter appointed as Regional Coordinator of the Ghana National Commission on Children in charge of Northern region from September 1986 to January 2009.</p>
                        <br/>
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Nayina was also Northern Regional Minister from February 2009 to 1st March, 2010. <br/><br/>In March 2011, he was appointed Board Member of Electricity Company of Ghana for a term of term (2) years. 
                        </p>  
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Stephen Sumani Nayina then retired from active service and is now a full time commercial farmer with emphasis on animal husbandry.
                        </p> 
                        <br/>
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        He is a Member of the Board of the Ghana Gas Company Limited and serves as Chairman of the Corporate Social Responsibility Committee and Member of the Audit Committee and Technical Committees.</p> 
                        <br />
                    </div>
                </div>
            </div>
            <br />


            <Footer />
        </>
    )

}

export default Leadership;