// Navbar.js
import React, { useState } from "react";
import NavImg from "./assets/nav-img.png";
import { useNavigate } from "react-router-dom";
import { FaPhone } from "react-icons/fa6";
import Logo from "./assets/company-logo.png";
import MobileSidebar from "./pages/testing";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsOpen(!isOpen);
  };

  let navigate = useNavigate();

  const AboutUs = () => {
    navigate("/aboutus");
  };

  const Careers = () => {
    navigate("/careers");
  };

  const Profile = () => {
    navigate("/profile");
  };

  const Mission = () => {
    navigate("/mission");
  };

  const Values = () => {
    navigate("/corporatevalues");
  };

  const OurPeople = () => {
    navigate("/ourpeople");
  };

  const Objectives = () => {
    navigate("/corporateobjectives");
  };

  const Environment = () => {
    navigate("/theenvironment");
  };

  const HSEPolicy = () => {
    navigate("/hsepolicy");
  };

  const HRPolicy = () => {
    navigate("/hrpolicy");
  };

  const LocalContent = () => {
    navigate("/localcontent");
  };

  const SocialInvestment = () => {
    navigate("/socialinvestment");
  };

  const RTI = () => {
    navigate("/righttoinformation");
  };

  const Strategy = () => {
    navigate("/strategy");
  };

  const Mandate = () => {
    navigate("/mandate");
  };

  const KeyObjectives = () => {
    navigate("/keyobjectives");
  };

  const BestPractices = () => {
    navigate("/bestpractices");
  };

  const Stakeholders = () => {
    navigate("/stakeholders");
  };

  const Tariff = () => {
    navigate("/tariff");
  };

  const Leadership = () => {
    navigate("/board");
  };

  const Operations = () => {
    navigate("/operations");
  };

  const Mentorship = () => {
    navigate("/mentorship");
  };

  const Media = () => {
    navigate("/media");
  };

  const Community = () => {
    navigate("/community");
  };

  const Home = () => {
    navigate("/");
  };

  const Contact = () => {
    navigate("/contactus")
  }

  const Scholarship = () => {
    navigate("/scholarship")
  }


  return (
    <>
      <nav className="bg-white p-4 sticky top-0 z-50">
        <div className="grid grid-cols-3 ">
          <div className="col-span-2 phone:hidden md:block">
            <div className="flex bg-white tablet:p-2 pt-3 pb-3 text-[12px] ] small-laptop:text-[12px] tablet:text-[10px] space-x-14">
              <span className="text-topblue font-fira hover:cursor-pointer" onClick={Media}>News</span>
              <span className="text-topblue font-fira hover:cursor-pointer" onClick={Community}>CSI</span>
              <span className="text-topblue font-fira hover:cursor-pointer" onClick={Contact}>Contact Us</span>
              <span className="text-topblue font-fira hover:cursor-pointer" onClick={Scholarship}>Company Scholarship</span>
              <span>
                <FaPhone className="" />
              </span>
              <a></a><span className="text-topblue font-fira ">+233 (0) 302 744200</span>
            </div>
          </div>
          <div className="col-span-1" >
            <img src={Logo} onClick={Home} className="w-[110px] h-[60px] macbook-air:ml-[20rem] small-laptop:ml-[15rem] phone:ml-[16rem] hover:cursor-pointer " />
            <div class="phone:block md:hidden lg:hidden ">
              <MobileSidebar />
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="hidden md:flex space-x-4 tablet:space-x-2 tablet:text-[14px] small-laptop:text-[17px] 4K-screen:text-[20px] font-fira text-[18px] small-laptop:pl-3">
            <div className="relative group left-[-16px] small-laptop:left-[-28px]">
              <a
                href="#"
                className="text-topblue hover:text-topblue p-6 hover:underline hover:decoration-4 hover:decoration-green-500 hover:underline-offset-4"
                onClick={Home}

              >
                Company
              </a>
              <div className="absolute z-10 hidden h-[400px] w-screen bg-topblue top-10 tablet:top-8 text-white group-hover:block right-0 left-0">
                <div className="flex ">
                  {/* First Column */}
                  <div className= "w-1/6 md:w-2/6 hp-desktop:w-1/6 pt-4">
                    <img src={NavImg} className="scale-75" />
                  </div>

                  {/* Second Column */}
                  <div className="w-1/6 pt-14 space-y-3 font-fira">
                    <div className="font-fjalla">Company</div>
                    <div className="hover:cursor-pointer" onClick={Profile}>
                      Profile
                    </div>
                    <div className="hover:cursor-pointer" onClick={Mission}>
                      Mission & Vision
                    </div>
                    <div className="hover:cursor-pointer" onClick={Values}>
                      Corporate Values
                    </div>
                    <div className="hover:cursor-pointer" onClick={Leadership}>
                      Leadership
                    </div>
                    <div className="hover:cursor-pointer" onClick={OurPeople}>
                      Our People
                    </div>
                    <div className="hover:cursor-pointer" onClick={AboutUs}>
                      About Us
                    </div>
                  </div>

                  {/* Third Column */}
                  <div className="w-1/8 pt-14">
                    <div className="w-auto mx-10 text-left">
                      Ghana National Gas Company (GNGC) is the nation’s premiere
                      mid-stream gas business company that owns and operates
                      infrastructure required for the gathering, processing, transporting and
                      marketing of natural gas resources in Ghana and
                      internationally.
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="relative group left-[-16px]">
              <a
                href="#"
                className="text-topblue hover:text-topblue p-5 hover:underline hover:decoration-4 hover:decoration-green-500 hover:underline-offset-4">
                Sustainable Business
              </a>
              <div className="absolute z-10 hidden h-[470px] left-[-146px] 4K-screen:left-[-150px] small-laptop:left-[-138px] tablet:left-[-113px] small-laptop:h-[500px] small-laptop:text-[17px] 4K-screen:text-[20px]  w-screen bg-topblue tablet:top-8 tablet:text-[16px] tablet:h-[400px] top-10 text-white group-hover:block right-0 left-0">
                <div className="flex ">
                  {/* First Column */}
                  <div className="w-1/6 md:w-2/6 hp-desktop:w-1/6 pt-4">
                    <img src={NavImg} className="scale-75" />
                  </div>

                  {/* Second Column */}
                  <div className="w-1/5 pt-14 space-y-3 font-fira">
                    <div className="font-fjalla" >Sustainable Business</div>
                    <div className="hover:cursor-pointer" onClick={Objectives}>
                      Corporate Objectives
                    </div>
                    <div className="hover:cursor-pointer" onClick={Environment}>
                      The Environment
                    </div>
                    <div className="hover:cursor-pointer" onClick={HSEPolicy}>
                      HSE Policy
                    </div>
                    <div className="hover:cursor-pointer" onClick={HRPolicy}>
                      HR Philosophy
                    </div>
                    <div
                      className="hover:cursor-pointer"
                      onClick={LocalContent}
                    >
                      Local Content
                    </div>
                    <div
                      className="hover:cursor-pointer"
                      onClick={SocialInvestment}
                    >
                      Social Investment
                    </div>
                    <div className="hover:cursor-pointer" onClick={Scholarship}>
                      Company Scholarship
                    </div>
                    <div className="hover:cursor-pointer" onClick={Mentorship}>
                      Mentorship Program
                    </div>
                    <div className="hover:cursor-pointer" onClick={RTI}>
                      Right To Information
                    </div>

                  </div>

                  {/* Third Column */}
                  <div className="w-1/8 pt-14">
                    <div className="mx-20 text-left">
                      Ghana National Gas Limited Company’s sustainable business is geared towards improving the lives of its stakeholders and the environment as a whole. <br />Our sustainable business is driven by three fundamental pillars of growth; Business Development, Capacity Building and Community Engagement.
                    </div>
                    <div className="mx-20 text-left">
                      These pillars of growth prioritize the health and safety, progress of our stakeholders and complement the United Nations Sustainable Development Goals
                      (UNSDG’s).
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <a
              className="text-topblue hover:text-topblue hover:cursor-pointer hover:underline hover:decoration-4 hover:decoration-green-500 hover:underline-offset-4"
              onClick={Operations}
            >
              Operations
            </a>


            <div className="relative group left-[-16px] ">
              <a
                href="#"
                className="text-topblue hover:text-topblue p-6 ml-10 hover:underline hover:decoration-4 hover:decoration-green-500 hover:underline-offset-4"
              >
                Investor Relations
              </a>
              <div className="absolute z-10 hidden h-[400px] left-[-440px] 4K-screen:left-[-495px] small-laptop:left-[-438px] tablet:left-[-379px] tablet:h-[20px] small-laptop:h-[350px] small-laptop:text-[17px] tablet:text-[11px] 4K-screen:text-[20px] w-screen bg-topblue top-10 tablet:top-8 text-white group-hover:block right-0 left-0">
                <div className="flex ">
                  {/* First Column */}
                  <div className="w-1/6 md:w-2/6 hp-desktop:w-1/6 pt-4">
                    <img src={NavImg} className="scale-75" />
                  </div>

                  {/* Second Column */}
                  <div className="w-1/5 pt-14 space-y-3 font-fira">
                    <div className="font-fjalla">
                      Investor Relations
                    </div>
                    <div className="hover:cursor-pointer" onClick={Strategy}>
                      Strategy
                    </div>
                    <div className="hover:cursor-pointer" onClick={Mandate}>
                      Mandate
                    </div>
                    <div
                      className="hover:cursor-pointer"
                      onClick={KeyObjectives}
                    >
                      Key Objectives
                    </div>
                    <div
                      className="hover:cursor-pointer"
                      onClick={BestPractices}
                    >
                      Best Practices
                    </div>

                    <div className="hover:cursor-pointer" onClick={Tariff}>
                      Tariff
                    </div>

                  </div>

                  {/* Third Column */}
                  <div className="w-1/8 pt-14">
                    <div className="mx-20 text-left">
                      Ghana National Gas Limited Company is proud of its innovative position as the only national gas aggregator mandated to acquire and sell gas along the domestic gas supply chain. <br />The Ghana National Gas Limited Company, being the only national gas aggregator, is well-positioned to supply the country's needs for LPG and other liquids for domestic and commercial applications,as well as the lean gas necessary for power generation, industrial process heating, fertilizer production.
                    </div>
                    <div className="mx-20 text-left">
                      In order to meet the rising demand for gas for power generation and industrial use, the company coordinates with all parties involved in Ghana's gas value chain and works with upstream partners to supply gas from Ghana's oil field.
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <a
              onClick={Careers}
              className="text-topblue hover:text-topblue hover:cursor-pointer hover:underline hover:decoration-4 hover:decoration-green-500 hover:underline-offset-4"
            >
              Careers{" "}
            </a>

            <a
              onClick={Media}
              className="text-topblue pl-10 hover:text-topblue hover:cursor-pointer hover:underline hover:decoration-4 hover:decoration-green-500 hover:underline-offset-4"
            >
              Media
            </a>
          </div>

          <div className="md:hidden flex items-center">

          </div>
        </div>


      </nav>
    </>
  );
}



export default Navbar;
