import React from 'react'
import Navigation from '../navbar'
import HeaderImage from '../assets/The Environment.png';
import Aburi from "../assets/AburiGirls.png";
import FaithPresby from "../assets/FaithPresby.png";
import Juansa from '../assets/Juansa.jpg'
import Dzodze from '../assets/Dzodze.jpg'
import Dawo from '../assets/Dawo.jpg'
import Beyin from '../assets/Beyin Classroom.jpg'
import Sekondi from '../assets/Sekondi Classroom.jpg'
import Marine from '../assets/Marine Police School.jpg'
import Patrick from '../assets/St Patrick Classroom.jpg'
import HalfAssini from '../assets/Half Assini School.jpg'
import Hockey from "../assets/Hockey Pitch.png"
import Damang from "../assets/Damang Astroturf.jpg"
import Tema from "../assets/Tema Tennis.png"
import Agogo from '../assets/Agogo.jpg'
import SekondiAstroturf from '../assets/Sekondi AstroTurf.jpg'
import Takoradi from '../assets/Takoradi Astroturf.jpg'
import Ampain from '../assets/Ampain Astroturf.jpg'
import Bomeng from "../assets/BomengHealth.png";
import Ellembelle from '../assets/Ellembelle Boreholes.jpg'
import Medical from '../assets/Medical Bungalow.jpg'
import TFacility from '../assets/Toilet.jpg'
import Axim from '../assets/Lower Axim.jpg'
import Footer from '../footer'

function Facility() {
    return (
        <>
            <div className="">
                {/* First Grid */}
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-4 phone:mt-[1rem] md:mt-[10rem] md:ml-[120px] phone:space-x-4">
                    <div className='flex md:flex-row'>
                        <div className='flex flex-col'>
                            <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[0.1rem] md:space-x-10 phone:space-y-3 md:space-y-0 phone:p-5'>
                                <div class="relative">
                                    <img src={Aburi} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover " />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Aburi Girls Assembly Hall Renovation</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                                <div class="relative">
                                    <img src={FaithPresby} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Faith Presbytarian Classroom Block</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p></div>
                                    </div>
                                </div>


                                <div class="relative">
                                    <img src={Juansa} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Juansa School Project</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                                <div class="relative">
                                    <img src={Dawo} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Dawo Teachers Quarters</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p></div>
                                    </div>
                                </div>


                                <div class="relative">
                                    <img src={Dzodze} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Dzodze Penji SHS Classroom Project</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                                <div class="relative">
                                    <img src={Marine} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px] phone:px-5">Rehabilitation of 4 Chamber and Hall Quarters for Marine Police Training School</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p></div>
                                    </div>
                                </div>


                                <div class="relative">
                                    <img src={Patrick} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]  phone:px-5">Rehabilitation of a 6- unit classroom block for St. Patrick Catholic School at Half Assini</p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                                <div class="relative">
                                    <img src={Beyin} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]  phone:px-5">6 - Unit Classroom Block with Ancillary facilities at Beyin</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p>
                                        </div>
                                    </div>
                                </div>


                                <div class="relative">
                                    <img src={Sekondi} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]  phone:px-5">6 - Unit Classroom Block with Concrete Roofed with Ancillary Facilities at Sekondi</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                                <div class="relative">
                                    <img src={HalfAssini} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]  phone:px-5">Renovation of 2 storey Girls Dormitory at Half Assini Senior High School</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p></div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                {/* First Grid */}
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-4 mt-[10rem] md:ml-[120px] phone:space-x-4">
                    <div className='flex md:flex-row'>
                        <div className='flex flex-col'>
                            <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[0.1rem] md:space-x-10 phone:space-y-3 md:space-y-0 phone:p-5'>
                                <div class="relative">
                                    <img src={Hockey} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover " />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Theodosia Okoh Hockey Pitch</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                                <div class="relative">
                                    <img src={Damang} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Damang Astroturf</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p></div>
                                    </div>
                                </div>


                                <div class="relative">
                                    <img src={Tema} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Tema Tennis Court</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                                <div class="relative">
                                    <img src={Agogo} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Agogo Astroturf</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p></div>
                                    </div>
                                </div>


                                <div class="relative">
                                    <img src={SekondiAstroturf} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Sekondi Astroturf</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                                <div class="relative">
                                    <img src={Takoradi} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Takoradi Astroturf</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p></div>
                                    </div>
                                </div>


                                <div class="relative">
                                    <img src={Ampain} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Ampain Astroturf</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
            <div className="">
                {/* First Grid */}
                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-4 mt-[10rem] md:ml-[120px] phone:space-x-4">
                    <div className='flex md:flex-row'>
                        <div className='flex flex-col'>
                            <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[0.1rem] md:space-x-10 phone:space-y-3 md:space-y-0 phone:p-5'>
                                <div class="relative">
                                    <img src={Bomeng} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover " />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Bomeng Health Center</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                                <div class="relative">
                                    <img src={Ellembelle} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]  phone:px-5">Construction of 10 boreholes in the Ellembelle District</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p></div>
                                    </div>
                                </div>


                                <div class="relative">
                                    <img src={Medical} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]  phone:px-5">Renovation of Medical Superintendent Bungalow at Half Assini</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                                <div class="relative">
                                    <img src={TFacility} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]  phone:px-5">Building of Modern Toilet Facility at Nkroful Agricultural SHS</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p></div>
                                    </div>
                                </div>


                                <div class="relative">
                                    <img src={Axim} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                                    <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                                        <div className='flex flex-col'>
                                            <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]  phone:px-5">Renovation at Lower Axim Government Hospital</p>
                                            <p class="text-white font-fira font-bold md:text-[15px]"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-10'>
                <Footer />
            </div>

        </>
    )
}

export default Facility;
