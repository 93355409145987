import React from 'react'
import HeaderImage from '../assets/executive.png'
import Navigation from '../navbar'
import Asmah from '../assets/leadership/owusu-bempah.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const Management = () => {
        navigate("/robertasmah")
    }

    const ExecuManagement = () => {
        navigate("/management")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Management
            </h1>

            <div class="grid md:grid-cols-3 phone:grid-cols-2 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Ernest Owusu-Bempah Bonsu</p>
                        <p class="text-black font-fira md:text-[20px] phone:text-[15px] md:mx-20 phone:mx-10">Head, Corporate Communications</p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            Ernest Owusu-Bempah Bonsu is head of the Corporate Communications Department at Ghana National Gas Company                        </p>

                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            He is responsible for the management of Ghana Gas’ internal and external publics. He serves as the mouth piece of Ghana Gas and keeps the company’s stakeholders and shareholders updated on a regular basis with the company’s business. </p>  <br />

                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            He oversees the strategic development plan that directs the activities of the Corporate Communication department and further, supports other departments to achieve their set goals for the greater good of Ghana Gas Company Limited.
                        </p>

                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        As a key member of Ghana Gas management, he diligently supports the Chief Executive to ensure that the vision and mission of Ghana Gas are effectively realized and that the objectives of the organization are successfully achieved.                        </p>

                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        He is of great knowledge with a variation of employment experience having been the sports editor of the Sankofa Magazine and also a news journalist for OBTV in the UK. He hosted the Morning Show on UK based Hot FM and is the Editor-In-Chief of The Ghana Eye Newspaper. He is a Public Relations consultant, a journalist, a political strategist and an image manager.                        </p>

                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Mr. Owusu-Bempah holds a diploma, first degree and masters in communication studies in Ghana’s premier communication institution, Ghana Institute of Journalism. He also read courses and holds certificates in communications, journalism, public relations and media management from schools abroad.                         </p>

                        <br />

                    </div>
                </div>
            </div>
            <br />

            <Footer />
        </>
    )

}

export default Leadership;