import React from 'react';
import HeaderImage from '../assets/About us.png';
import Footer from '../footer'
import Navigation from '../navbar'

function Page() {
  return (
    <>
    <Navigation/>
    <div className="relative">
      <div className="absolute inset-0 bg-black opacity-50 flex">
        <h1 className="text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-20">
         
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
        src={HeaderImage}
        alt="Ghana Gas GPP"
      />
    </div>
    <div>
    <h1 className="text-dark text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-20">
    About Ghana Gas
    </h1>
    <h1 className="text-dark text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:text-[30px] xl:mx-20">
    Ghana National Gas Company (GNGC) is the nation’s premiere mid-stream gas business company that owns and operates infrastructure required for the gathering, processing, transporting and marketing of natural gas resources in Ghana and internationally.
    </h1>

    <h1 className="text-dark text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[30px] xl:mx-20  xl:leading-snug">
    GNGC although incorporated in July 2011, started official production in November 2014. The company is responsible for producing and prospecting of lean gas, condensate, LPG and isopentanes and further, plays a key role in accelerating the nation’s industrial sector.    
    </h1>
    <h1 className="text-dark text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[30px] xl:mx-20  xl:leading-snug">
    Ghana Gas operates on a business model and thus, obtains revenue through the processing, transportation and sale of natural gas and natural gas liquids. GNGC currently supplies gas to Volta River Authority (VRA) for power generation.    </h1>
    <h1 className="text-dark text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[30px] xl:mx-20 xl:leading-snug">
    The company has its headquarters in the nation’s capital, Accra, with the gas plant situated at Atuabo, in the Western region of Ghana. Ghana Gas is a trusted and reliable gas company and contributes to Ghana’s economic development.    </h1>

    <div className="relative hover:cursor-pointer">
      <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black opacity-50 flex">
        <h1 className="text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
          About Us
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
        src={HeaderImage}
        alt="Ghana Gas GPP"
      />
    </div>

    <div className="relative hover:cursor-pointer">
      <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black opacity-50 flex">
        <h1 className="text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
          About Us
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
        src={HeaderImage}
        alt="Ghana Gas GPP"
      />
    </div>
<Footer/>
    </div>
    </>
  );
}

export default Page;
