import React from 'react'
import HeaderImage from '../assets/executive.png'
import Board from '../assets/Board.png';
import OurPeople from '../assets/leadership.jpg';
import Navigation from '../navbar'
import CEO from '../assets/leadership/ceo.png'
import Jomo from '../assets/leadership/jomo.png'
import Bempah from '../assets/leadership/owusu-bempah.png'
import Sylvia from '../assets/leadership/sylvia.png'
import Natasha from '../assets/leadership/natasha.png'
import Rene from '../assets/leadership/rene.png'
import Asmah from '../assets/leadership/asmah.png'
import Jeremiah from '../assets/leadership/jeremiah.png'
import Augustina from '../assets/leadership/augustina.png'
import RobertLartey from '../assets/leadership/robert-lartey.png'
import NanaYaa from '../assets/leadership/nana-yaa.png'
import Ben from '../assets/leadership/benjamin.png'
import Albert from '../assets/leadership/albert.png'
import Alma from '../assets/leadership/alma.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
  let navigate = useNavigate();

  const BoardDirector = () => {
    navigate("/board")
  }
  const People = () => {
    navigate("/management")
  }
  const Lartey = () => {
    navigate("/robertlartey")
  }
  const RobertAsmah = () => {
    navigate("/robertasmah")
  }
  const AlmaMensah = () => {
    navigate("/almamensah")
  }
  const BenBaidoo = () => {
    navigate("/benjaminbaidoo")
  }
  const JeremiahAddo = () => {
    navigate("/jeremiahaddotwum")
  }
  const Nana = () => {
    navigate("/nanayaa")
  }
  const StephenJomo = () => {
    navigate("/stephenjomo")
  }
  const Nylander = () => {
    navigate("/natashanylander")
  }
  const SylviaArcher = () => {
    navigate("/sylviaarcher")
  } 

  const ReneNelson = () => {
    navigate("/renenelson")
  } 
  return (

    <>

      <Navigation />

      <div className="relative">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
          src={HeaderImage}
          alt="Ghana Gas GPP"
        />
      </div>

      <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
        Executive Management
      </h1>

      <div class="grid md:grid-cols-3 gap-4 phone: grid-cols-2">
        <div class="col-span-1 flex items-center justify-end">
          <img src={CEO} alt="CEO Image" className='scale-75' />
        </div>
        <div class="col-span-2 flex items-center">
          <div>
            <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Dr. Ben K. D. Asante</p>
            <p class="text-black font-fira text-[20px] md:mx-20 phone:mx-10">Chief Executive Officer</p>
            <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
              Dr. Ben Asante is a Chemical & Petroleum Engineer by training. He has been in the Oil and Gas Industry for a little over 30 years now.
              He has worked for major Operating Companies as well as Engineering Consulting firms (in Canada, the USA, and Ghana). He has also been a Consultant for the World bank and Asian Development bank; and consulted for over 25 countries in 5 continents. He is currently the CEO of Ghana National Gas Company . He is also an academic; teaches Oil and Gas related courses at Kwame Nkrumah University of Science & Technology (KNUST) in Ghana, taught at the University of Calgary in Canada, and Imperial College in London, England. His Bachelors, Masters and Doctorate are all in Chemical & Petroleum Engineering
            </p>
          </div>
        </div>
      </div>




      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={Lartey}>
          <img src={RobertLartey} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Robert Kofi Lartey</span>
          <span className='font-fira text-[17px]'>General Manager, Operations</span>
        </div>
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={RobertAsmah}>
          <img src={Asmah} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Robert Asmah</span>
          <span className='font-fira text-[17px]'>General Manager, Technical Services</span>
        </div>
      </div>

      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={Nylander}>
          <img src={Natasha} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Natasha Naa Koshie Nylander</span>
          <span className='font-fira text-[17px]'>General Manager, Legal</span>
        </div>
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={ReneNelson}>
          <img src={Rene} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Rene Stephen Nelson</span>
          <span className='font-fira text-[17px]'>General Manager, Accounting</span>
        </div>
      </div>

      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={JeremiahAddo}>
          <img src={Jeremiah} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Jeremiah Addo-Twum</span>
          <span className='font-fira text-[17px]'>General Manager, Finance</span>
        </div>
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={AlmaMensah}>
          <img src={Alma} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Alma-Leigh Mensah</span>
          <span className='font-fira text-[17px]'>General Manager, Human Resource</span>
        </div>
      </div>

      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={StephenJomo}>
          <img src={Jomo} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Stephen Borteye Jomo</span>
          <span className='font-fira text-[17px]'>General Manager, Commercial Operations</span>
        </div>
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={BenBaidoo}>
          <img src={Ben} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Benjamin Baidoo</span>
          <span className='font-fira text-[17px]'>General Manager, Procurement & Logistics</span>
        </div>
      </div>

      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">

        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={Nana}>
          <img src={NanaYaa} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Nana Yaa Konama Ofori-Atta</span>
          <span className='font-fira text-[17px]'>General Manager, Administration and Facilities
            Management</span>
        </div>

        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={SylviaArcher}>
          <img src={Sylvia} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Sylvia Assimeng Archer</span>
          <span className='font-fira text-[17px]'>General Manager, Commercial Legal</span>
        </div>
      </div>

<br/>
<br/>
      <div className="relative hover:cursor-pointer" onClick={BoardDirector}>
        <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
            Board of Directors
          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
          src={Board}
          alt="Ghana Gas GPP"
        />
      </div>

      <div className="relative hover:cursor-pointer" onClick={People}>
        <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
            Management
          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
          src={OurPeople}
          alt="Ghana Gas GPP"
        />
      </div>


      <Footer />
    </>
  )

}

export default Leadership;