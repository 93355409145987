import React from 'react'
import HeaderImage from './assets/our people.png'
import Leadership from './assets/leadership.jpg';
import Mentorship from './assets/Mentorship.png';
import Work from './assets/Work at Ghana Gas.png';
import { useNavigate } from 'react-router-dom';


function MobileView(){
  let navigate = useNavigate();

  const People = () => {
      navigate("/ourpeople")
  }
  const Leaders = () => {
    navigate("/leadership")
  }
  

    return(

        <>
<div className="relative" onClick={People}>
  <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center" >
    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:ml-10 xl:text-6xl xl:mx-[270px]">
      Our People
    </h1>
  </div>
  <img
    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full"
    src={HeaderImage}
    alt="Ghana Gas GPP"
  />
</div>

<div className="relative">
  <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:ml-10 xl:text-6xl xl:mx-[270px]">
      Work at Ghana Gas
    </h1>
  </div>
  <img
    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full"
    src={Work}
    alt="Ghana Gas GPP"
  />
</div>

<div className="relative" onClick={Leaders}>
  <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:ml-10 xl:text-6xl xl:mx-[270px]">
      Leadership
    </h1>
  </div>
  <img
    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full"
    src={Leadership}
    alt="Ghana Gas GPP"
  />
</div>

<div className="relative">
  <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:ml-10 xl:text-6xl xl:mx-[270px]">
      Our Culture
    </h1>
  </div>
  <img
    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full"
    src={Mentorship}
    alt="Ghana Gas GPP"
  />
</div>
      
              </>


    )
}

export default MobileView;