import React, { useState } from 'react';
import { useNavigate } from "react-router-dom"
import Flame from "./assets/SHE2Slider.jpg"
import Security from './assets/marine-security.jpg'
import GasProcessing from './assets/pipelines.jpg'
import Pipelines from './assets/Gas Processing Plant2.jpg'

const ImageStack = () => {
  const [activeImage, setActiveImage] = useState(1);

  const handleImageHover = (imageNumber) => {
    setActiveImage(imageNumber);
  };

  let navigate = useNavigate();

  const People = () => {
    navigate('/ourpeople');
  };

  return (
    <>
      <div className="flex w-full sm:h-[200px] md:h-[500px] small-laptop:h-[520px] macbook-air:h-[590px] macbook:h-[560px] md:h-[600px] hp-desktop:h-[780px] tablet:h-[392px] 4K-screen:h-[1040px] laptop:h-[420px] absolute z-10 overflow-x-hidden overflow-y-hidden">
        {/* Column 1 */}
        <div className="w-1/4 p-4 bg-black bg-opacity-10 hover:cursor-pointer transition ease-in duration-500 hover:bg-black hover:bg-opacity-20" onMouseOver={() => handleImageHover(4)} onClick={People}>
          <h2 className="font-fjalla text-white text-4xl small-laptop:mt-[20rem] hp-desktop:mt-[35rem] macbook-air:mt-[22rem] tablet:mt-[12rem] tablet:text-[27px] 4K-screen:text-[50px]">
            Health, Safety and Environment</h2>
          <p className={`text-white font-fira transition ease-in duration-1000  ${activeImage === 4 ? 'block' : 'hidden'}`}></p>
        </div>


        {/* Column 2 */}
        <div className="w-1/4 border-l-2 border-white p-4 hover:cursor-pointer bg-black bg-opacity-10 hover:bg-black hover:bg-opacity-20" onMouseOver={() => handleImageHover(3)}>
          <h2 className="text-xl font-semibold"></h2>
          <h2 className="font-fjalla text-white text-4xl small-laptop:mt-[20rem] hp-desktop:mt-[35rem] macbook-air:mt-[22rem] tablet:mt-[12rem] tablet:text-[27px] 4K-screen:text-[50px]">
            Marine and Security</h2>
          <p className={`text-white font-fira transition ease-in duration-1000 xl:pt-6 ${activeImage === 3 ? 'block' : 'hidden'}`}></p>

        </div>

        {/* Column 3 */}
        <div className="w-1/4 p-4 border-l-2 bg-black hover:cursor-pointer bg-opacity-10 hover:bg-black hover:bg-opacity-20" onMouseOver={() => handleImageHover(1)}>
          <h2 className="font-fjalla text-white text-4xl small-laptop:mt-[20rem] hp-desktop:mt-[35rem] macbook-air:mt-[22rem] tablet:mt-[12rem] tablet:text-[27px] 4K-screen:text-[50px]">
            Gas Plant Operations</h2>
          <p className={`text-white font-fira transition ease-in duration-1000 xl:pt-6 ${activeImage === 1 ? 'block' : 'hidden'}`}></p>

        </div>

        {/* Column 4 */}
        <div className="w-1/4 p-4 border-l-2 bg-black hover:cursor-pointer bg-opacity-10 hover:bg-black hover:bg-opacity-20" onMouseOver={() => handleImageHover(2)}>
          <h2 className="font-fjalla text-white text-4xl small-laptop:mt-[20rem] hp-desktop:mt-[35rem] macbook-air:mt-[22rem] tablet:mt-[12rem] tablet:text-[27px] 4K-screen:text-[50px]">
            Pipelines and Stations</h2>
          <p className={`text-white font-fira transition ease-in duration-1000 xl:pt-6 ${activeImage === 2 ? 'block' : 'hidden'}`}></p>

        </div>
      </div>

      <div className="relative">
        <img
          src={Flame}
          alt="Image 4"
          onMouseOver={() => handleImageHover(4)}
          className={`absolute w-full inset-0 transition-opacity duration-700 ${activeImage === 4 ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        />

        <img
          src={Security}
          alt="Image 3"
          onMouseOver={() => handleImageHover(3)}
          className={`absolute w-full inset-0 transition-opacity duration-700 ${activeImage === 3 ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        />

        <img
          src={GasProcessing}
          alt="Image 2"
          onMouseOver={() => handleImageHover(2)}
          className={`absolute w-full inset-0 transition-opacity duration-700 ${activeImage === 2 ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        />

        <img
          src={Pipelines}
          alt="Image 1"
          onMouseOver={() => handleImageHover(1)}
          className={`absolute w-full inset-0 transition-opacity duration-700 ${activeImage === 1 ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        />
      </div>
    </>
  );
};

export default ImageStack;
