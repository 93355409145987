import React from 'react'
import HeaderImage from '../assets/executive.png'
import Navigation from '../navbar'
import Asmah from '../assets/leadership/augustina.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const Management = () => {
        navigate("/robertasmah")
    }

    const ExecuManagement = () => {
        navigate("/management")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Management
            </h1>

            <div class="grid md:grid-cols-3 phone:grid-cols-2 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Augustina Asare Osei</p>
                        <p class="text-black font-fira md:text-[20px] phone:text-[15px] md:mx-20 phone:mx-10">Head, Government Relations</p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Augustina Asare Osei is head of the Government Relations department at Ghana National Gas Company
                        </p>

                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        She leads the department to maintain continuous engagements with key stakeholders (i.e. both Regulators and Non-regulators) in an effort to ensure greater integration and collaboration with policy makers, Government agencies and institutions; corporate entities among others.
                        </p>  <br />

                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        As part of the brand recognition strategies, Ms. Osei initiated The Gas Challenge, a quiz competition which seeks to enlighten students and key industry players on gas-related matters as well as addressing misconceptions about the operations of the gas industry and the production of indigenous gas.  
                        </p>

                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        She helped GNGC in obtaining approval from the Land Valuation Division to commence payments of outstanding land compensations owed to forty-seven (47) claimants within the Aboadze and Ahanta enclaves.                        </p>

                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        She also initiated Ghana’s successful membership bid to join the International Gas Union (IGU) in 2018.                         
                        </p>

                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Being a woman with great ambition, Ms. Osei has a Bachelor of Law (LL. B) from The Netherlands and holds a Master’s Degree in International Political Communication, Advocacy and Campaigning from Kingston University, London.                        
                        </p>

                        <br />

                    </div>
                </div>
            </div>
            <br />

            <Footer />
        </>
    )

}

export default Leadership;