import React from 'react'
import HeaderImage from '../assets/executive.png'
import Navigation from '../navbar'
import Asmah from '../assets/leadership/jeremiah.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const Management = () => {
        navigate("/robertasmah")
    }

    const ExecuManagement = () => {
        navigate("/leadership")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Executive Management
            </h1>

            <div class="grid md:grid-cols-3 phone:grid-cols-2 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Jeremiah Addo-Twum</p>
                        <p class="text-black font-fira text-[20px] md:mx-20 phone:mx-10">General Manager, Finance</p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Jeremiah is a highly accomplished, results-driven senior finance management executive with over 18 years of progressive experience in finance, leading and building finance teams in global companies of significant scale and complexity. 
                            </p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        His expertise cuts across multiple industries, especially in the FMCG and telecommunications sectors. Jeremiah has a track record of streamlining business operations, which has led to business growth, increased efficiency, and improved bottom-line profitability.</p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        He holds strong qualifications in developing and implementing financial controls, productivity improvements, and change management. His areas of expertise include business strategy development and implementation, balance sheet restructuring, budgeting and planning, financial operations, and the raising of funds for high capital-intensive projects.
</p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Prior to joining Ghana Gas, Jeremiah served as Financial Controller and Head of Financial Planning & Analysis at Vodafone Ghana at different times. He was also appointed Interim CFO, where he spearheaded a game-changing restructuring of the company's balance sheet, significantly enhancing the business's equity position.                        </p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Jeremiah also held the position of Head of Commercial Finance at Diageo Ghana Limited, where he led the development of the 5-year strategic plan and the Advanced Route to Consumer distribution model, driving significant top-line growth.                            
                        </p>
                        <br/>
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Before relocating to Ghana to join Millicom Ghana Ltd as the Director of Financial Planning, he worked for Procter and Gamble (P&G) in the U.S.A as Finance Project Manager overseeing the implementation of a financial forecast system which consolidated circa $85 billion sales globally.                          
                        </p>
                        <br/>
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        He also served as Commercial Finance Manager for brands like Pepto Bismol and Align, supporting the development of a 3-year Strategic Revenue Management Plan that drove substantial revenue growth and profitability.                        </p>
                        <br/>
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Jeremiah holds an MBA with a focus on Accounting, Finance, and Strategy from Purdue University in Indiana, USA, and a B.Sc. in Administration (Banking and Finance) from the University of Ghana. He is also a member of the CFA (Chartered Financial Analyst) Institute, Cincinnati Chapter in the USA. Jeremiah has previously served on the Board of Vodacom Business Africa and currently serves on the Board of Q1 Security Services.</p>
                        <br/>
                        
                    </div>
                </div>
            </div>
            <br />

            <Footer />
        </>
    )

}

export default Leadership;