import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Operations from './assets/operations.png';
import Sustainable from './assets/sustainable-business.png'
import Investor from './assets/investor-relations.png'
import Careers from './assets/careers.png'

const CarouselWithOverlay = () => {
  return (
    <Carousel
      autoPlay={true}
      interval={5000} // Set the interval for auto-advancing slides
      infiniteLoop={true}
      showThumbs={false} // Hide thumbnail navigation
      showStatus={false} // Hide status bar
      showArrows={false} // Show navigation arrows
      dynamicHeight={true} // Disable dynamic height based on content
      emulateTouch={true} // Enabzle touch swipe on mobile devices
    >
      <div className="relative">
        <img src={Operations} alt="Image 1" className='4K-screen:h-[1110px]  tablet:h-[550px] hp-desktop:h-[800px] small-laptop:h-[600px] phone:h-[250px] sm:h-[400px] md:h-[600px] md:object-cover lg:h-[600px] lg:object-fit xl:h-[840px] md:object-cover phone:object-cover xl:top-0'/>
        <div className="absolute inset-0 flex items-center justify-end mr:200px lg:mr-[300px] small-laptop:mr-[100px] tablet:mr-[80px]">
          <div className="bg-opacity-30 md:rounded-xl phone:rounded-none bg-[#00193E] text-white p-4 small-laptop:h-60 h-64 tablet:h-[13rem]  w-[30rem] small-laptop:w-[25rem] tablet:w-[20rem]">
            <div className='flex flex-col items-start'>
            <h2 className="text-2xl text-white font-fjalla small-laptop:text-[20px] tablet:text-[18px]">Operations</h2>
            <h2 className="text-2xl text-white text-lg text-justify font-fira small-laptop:text-[17px] tablet:text-[15px] tablet:leading-snug ">Ghana National Gas Limited Company’s operations focus on the utilization of associated natural gas resources from offshore oil production to generate power, provide subsidized gas for non-power industrial use, as well as the supply of clean energy for domestic use.</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <img src={Investor} alt="Image 1" className='4K-screen:h-[1110px] tablet:h-[550px] hp-desktop:h-[800px] small-laptop:h-[600px] phone:h-[250px] sm:h-[500px] md:h-[600px] md:object-cover phone:object-cover lg:h-[600px] lg:object-cover xl:h-[840px] xl:object-cover'/>
        <div className="absolute inset-0 flex items-center justify-end mr:200px lg:mr-[300px] small-laptop:mr-[100px]  tablet:mr-[80px]">
        <div className="bg-opacity-30 rounded-xl phone:rounded-none md:rounded-xl bg-[#00193E] text-white p-4 small-laptop:h-60 h-64 tablet:h-[13rem]  w-[30rem] small-laptop:w-[25rem] tablet:w-[20rem]">
          <div className='flex flex-col items-start'>
            <h2 className="text-2xl text-white font-fjalla small-laptop:text-[20px] tablet:text-[18px]">Investor Relations</h2>
            <h2 className="text-2xl text-white text-lg text-justify font-fira small-laptop:text-[17px] tablet:text-[15px] tablet:leading-snug">In order to meet the rising demand for gas for power generation and industrial use, the company coordinates with all parties involved in Ghana's gas value chain and works with upstream partners to supply gas from Ghana's oil field.</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <img src={Sustainable} alt="Image 1" className='4K-screen:h-[1110px] tablet:h-[550px] hp-desktop:h-[800px] small-laptop:h-[600px] phone:h-[250px] sm:h-[500px] md:h-[600px] md:object-cover phone:object-cover lg:h-[600px] lg:object-cover xl:h-[840px] xl:object-cover'/>
        <div className="absolute inset-0 flex items-center justify-end mr:200px lg:mr-[300px] small-laptop:mr-[100px] tablet:mr-[80px]">
        <div className="bg-opacity-30 md:rounded-xl phone:rounded-none bg-[#00193E] text-white p-4 small-laptop:h-60 h-64 tablet:h-[13rem]  w-[30rem] small-laptop:w-[25rem] tablet:w-[20rem]">
          <div className='flex flex-col items-start'>
            <h2 className="text-2xl text-white font-fjalla small-laptop:text-[20px] tablet:text-[18px]">Sustainable Business</h2>
            <h2 className="text-2xl text-white text-lg text-justify font-fira small-laptop:text-[17px] tablet:text-[15px] tablet:leading-snug">Ghana National Gas Limited Company’s sustainable business is geared towards improving the lives of its stakeholders and the environment as a whole. Our sustainable business is driven by three fundamental pillars of growth; Business Development, Capacity Building and Community Engagement.</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <img src={Careers} alt="Image 1" className='4K-screen:h-[1110px] tablet:h-[550px] hp-desktop:h-[800px] small-laptop:h-[600px] phone:h-[250px] sm:h-[500px] md:h-[600px] md:object-cover phone:object-cover lg:h-[600px] lg:object-cover xl:h-[840px] xl:object-cover'/>
        <div className="absolute inset-0 flex items-center justify-end mr:200px lg:mr-[300px] small-laptop:mr-[100px] tablet:mr-[80px]">
        <div className="bg-opacity-30 md:rounded-xl phone:rounded-none bg-[#00193E] text-white p-4 small-laptop:h-60 h-64 tablet:h-[13rem]  w-[30rem] small-laptop:w-[25rem] tablet:w-[20rem]">
          <div className='flex flex-col items-start'>
            <h2 className="text-2xl text-white font-fjalla small-laptop:text-[20px] tablet:text-[18px]">Careers</h2>
            <h2 className="text-2xl text-white text-lg text-justify font-fira small-laptop:text-[17px] tablet:text-[15px] tablet:leading-snug">Ghana Gas has a strong presence in all aspects of the oil and gas industry. As one of the largest Ghanaian-based energy companies, Ghana Gas produces natural gas and other products essential for the progress of Ghana.</h2>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default CarouselWithOverlay;
