import React, { useState } from 'react';
import Aburi from "./assets/AburiGirls.png";
import Bomeng from "./assets/BomengHealth.png";
import Hockey from "./assets/Hockey Pitch.png";
import Damang from "./assets/Damang Astroturf.jpg";
import Tema from "./assets/Tema Tennis.png";
import {
  TbSquareArrowRightFilled,
  TbSquareArrowLeftFilled,
} from "react-icons/tb";

const images = [
  { id: 1, src: Aburi, title: 'Aburi Girls Assembly Hall Renovation' },
  { id: 2, src: Bomeng, title: 'Bomeng Health Center' },
  { id: 4, src: Hockey, title: 'Theodosia Okoh Hockey Pitch' },
  { id: 5, src: Damang, title: 'Damang Astroturf' },
  { id: 6, src: Tema, title: 'Tema Tennis Court' },
];

const App = () => {
  const [visibleImages, setVisibleImages] = useState([
    images[0],
    images[1],
    images[2],
    images[3],
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const showNextImages = () => {
    // Calculate the next set of images in a loop
    const nextIndex = (currentIndex + 1) % images.length;
    const nextImages = [
      images[nextIndex],
      images[(nextIndex + 1) % images.length],
      images[(nextIndex + 2) % images.length],
      images[(nextIndex + 3) % images.length],
    ];

    setVisibleImages(nextImages);
    setCurrentIndex(nextIndex);
  };

  const showPreviousImages = () => {
    // Calculate the previous set of images in a loop
    const previousIndex = (currentIndex - 1 + images.length) % images.length;
    const previousImages = [
      images[previousIndex],
      images[(previousIndex + 1) % images.length],
      images[(previousIndex + 2) % images.length],
      images[(previousIndex + 3) % images.length],
    ];

    setVisibleImages(previousImages);
    setCurrentIndex(previousIndex);
  };

  return (
    <div className="flex flex-col mt-3 small-laptop:mt-1 tablet:mt-8 laptop:mt-[9rem]">
      <div className='flex flex-row justify-between items-center'>
        <h1 className='font-fira text-2xl p-4 tablet:text-3xl'>Our CSI</h1>
        <div className='flex pr-5'>
          <TbSquareArrowLeftFilled
            onClick={showNextImages}
            className='h-7 w-7 cursor-pointer'
          />
          <TbSquareArrowRightFilled onClick={showPreviousImages} className='h-7 w-7 cursor-pointer' />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row w-full md:space-x-5 px-5">
        {visibleImages.map((image) => (
          <div key={image.id} className="flex flex-col sm:w-1/4 mt-8">
            <div className="relative">
              <img src={image.src} alt={`Image ${image.id}`} />
              <h1 className='font-fjalla text-lg sm:text-xl absolute inset-0 text-center text-white bg-black bg-opacity-50 flex justify-center items-center'>
                {image.title}
              </h1>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
