import React from 'react'
import { useNavigate } from 'react-router-dom';




function Glance(){
    
  let navigate = useNavigate();
const Facilities  = () => {
  navigate("/facilities")
}
const Infrastructure  = () => {
  navigate("/infrastructure")
}
const History  = () => {
  navigate("/history")
}
const Events  = () => {
  navigate("/events")
}

const Certifications = () => {
  navigate("/certifications")
}

  return(
        <div className=" grid grid-cols-1 mt-20 tablet:mt-[3rem] small-laptop:mt-10 xs:h-[80px] sm:h-[100px] md:h-[200px] lg:h-[370px] xl:[300px] sm:grid-cols-2 sm:mx-5 mx-5 md:mx-10 small-laptop:mb-[50px] tablet:mb-[15rem] mb-[90px] text-center md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {/* Your content for each column */}
  
        <div className="bg-gasblue pt-14 sm:p-8 lg:rounded-l-[1px] xl:rounded-l-[30px] lg:pt-[7rem] text-white md:border-r-[1px] phone:border-b-[1px] font-fjalla text-[31px]">10 years in <br/>existence<br/>
        <button class="transition ease-in-out rounded-[5px] border-[1px] border-white hover:bg-white duration-700 hover:text-black bg-gasblue px-4 text-white font-fira text-[25px] phone:mb-5" onClick={History}>History</button></div>
        <div className="bg-gasblue pt-14 sm:p-8 lg:pt-[7rem] text-white md:border-r-[1px] font-fjalla text-[31px] phone:border-b-[1px]">6 facilities across <br/>the nation<br/>
        <button class="transition ease-in-out rounded-[5px] border-[1px] border-white hover:bg-white duration-700 hover:text-black bg-gasblue px-4 text-white font-fira text-[25px] phone:mb-5" onClick={Facilities} >Facilities</button></div>
        <div className="bg-gasblue pt-14 sm:p-8 lg:pt-[7rem] text-white md:border-r-[1px] font-fjalla text-[31px] phone:border-b-[1px]">300km pipeline <br/>infrastructure<br/>
        <button class="transition ease-in-out rounded-[5px] border-[1px] border-white hover:bg-white duration-700 hover:text-black bg-gasblue px-4 text-white font-fira text-[25px] phone:mb-5" onClick={Infrastructure}>Infrastructure</button></div>
        <div className="bg-gasblue pt-14 sm:p-8 lg:pt-[7rem] text-white md:border-r-[1px] font-fjalla text-[31px] phone:border-b-[1px]">ISO 45001:2018 <br/>certified<br/>
        <button class="transition ease-in-out rounded-[5px] border-[1px] border-white hover:bg-white duration-700 hover:text-black bg-gasblue px-4 text-white font-fira text-[25px] phone:mb-5" onClick={Certifications}>Certifications</button></div>
        <div className="bg-gasblue pt-14 sm:p-8 lg:rounded-r-[1px] xl:rounded-r-[30px] lg:pt-[7rem] text-white font-fjalla text-[31px]">Corporate <br/>Events<br/>
        <button class="transition ease-in-out rounded-[5px] border-[1px] md:border-white hover:bg-white duration-700 hover:text-black bg-gasblue px-4 text-white font-fira text-[25px] phone:mb-5" onClick={Events}>Events</button></div>
        
      </div>
    )
}

export default Glance;