import React from 'react'
import HeaderImage from '../assets/executive.png'
import Navigation from '../navbar'
import Asmah from '../assets/leadership/albert.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const Management = () => {
        navigate("/robertasmah")
    }

    const ExecuManagement = () => {
        navigate("/management")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Management
            </h1>

            <div class="grid md:grid-cols-3 phone:grid-cols-2 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Albert Nanevie</p>
                        <p class="text-black font-fira md:text-[20px] phone:text-[15px] md:mx-20 phone:mx-10">Head, Internal Audit</p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        He is an accountant with over 20 years of experience in auditing, risk management, accounting and SOX compliance in several sectors including gas, public accounting, life insurance, manufacturing (aerospace & building systems) and investment management.  
                        </p>

                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        He joined Ghana Gas in 2014 and responsible for setting up the Internal Audit department and guiding its growth.  The department is responsible for auditing (financial and operational), internal controls review and risk management activities in the company. Additionally, he is responsible for leading the corporate strategy development.                           </p>  <br />

                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        He is a Certified Public Accountant (CPA) and a member of the Institute of Internal Auditors (IIA Ghana), American Institute of Certified Public Accountants (AICPA) and the Institute of Chartered Accountants Ghana (ICAG).  He holds a Masters in Finance from Kelley School of Business, Indiana University, a Masters in Applied Commerce from the University of Melbourne and a Bachelor of Education (Social Science) from the University of Cape Coast.                         </p>

                        <br />

                    </div>
                </div>
            </div>
            <br />

            <Footer />
        </>
    )

}

export default Leadership;