import React from 'react'
import Navigation from '../navbar'
import HeaderImage from '../assets/gaschallenge.jpg';
import Footer from '../footer'
import GC19 from '../assets/gc2019.jpg'
import GC21 from '../assets/gc2021.jpg'
import GC22 from '../assets/gc2022.jpg'
import GasChallenge from '../assets/gas-challenge.jpg'
import GhanaEnergy from '../assets/ghanaenergy.jpg'
import MediaSoiree from '../assets/mediasoiree.jpg'
import MeetThePress from '../assets/meetthepress.jpg'
import GasCommercialisation from '../assets/gascommercialisation.jpg'

function Events(){
return(
<>
<Navigation />
<div className="relative">
      <div className="absolute inset-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
Events
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
        src={HeaderImage}
        alt="Ghana Gas GPP"
      />
    </div>


<div className="">
      {/* First Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-4 mt-[10rem] mb-[5rem] md:ml-[120px] phone:space-x-4">
        <div className='flex md:flex-row'>
            <div className='flex flex-col'>
              <div className='flex md:flex-row phone:flex-col md:space-x-10  '>
                <div class="relative">
                  <img src={GasChallenge} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover " />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Gas Challenge 2023</p>
                    </div>
                  </div>
                </div>

                <div class="relative">
                  <img src={GC22} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Gas Challenge 2022</p>
                    </div>
                  </div>
                </div>


              </div>
              <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                <div class="relative">
                  <img src={GC21} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Gas Challenge 2021</p>
                    </div>
                  </div>
                </div>


                <div class="relative">
                  <img src={GC19} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px] px-2 text-center">Gas Challenge 2019</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                <div class="relative">
                  <img src={GasCommercialisation} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px] text-center px-2">Gas Commercialisation Workshop</p>
                    </div>
                  </div>
                </div>


                <div class="relative">
                  <img src={MeetThePress} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px] text-center px-2">Meet the Press </p>
                    </div>
                  </div>
                </div>
              </div>


              <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                <div class="relative">
                  <img src={MediaSoiree} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Media Soiree</p>
                    </div>
                  </div>
                </div>


                <div class="relative">
                  <img src={GhanaEnergy} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px] text-center px-2">Ghana Energy Awards</p>
                    </div>
                  </div>
                </div>
              </div>

              
            </div>
          </div>



          <span className='md:bg-black md:h-full phone:bg-black phone:text-black md:w-[546px] md:ml-[12rem] phone:ml-[30px] phone:w-[400px] text-justify'>
          <h1 className='font-fjalla text-white pl-6 pt-4 xl:text-[23px]'>The Gas Challenge</h1>
<h1 className='text-white font-fira p-6 md:text-[12px] lg:text-[15px] xl:text-[19px] text-justify'>The Gas Challenge is an award-winning tertiary-level quiz competition in Ghana. Since its inception in 2019, the initiative has educated students engaged in oil and gas academic pursuits as well as the novel Science, Technology, Engineering and Mathematics (STEM) related courses in Ghanaian tertiary institutions. Through the previous competitions, the Ghana National Gas Limited Company (GNGLC) has offered internships, national service and employment opportunities to contestants from participating institutions to enhance their human resource capacities. The company has also sponsored some of the participating institutions through the provision of relevant educational infrastructures to aid their teaching and learning. This has helped establish an excellent working relationship between GNGLC and academia.</h1>
<h1 className='text-white font-fira p-6 md:text-[12px] lg:text-[15px] xl:text-[19px] text-justify'>
Initially, the competition started with nine tertiary institutions in its first two editions. Then, on its third version in 2022, three more institutions were added to increase the participating institutions to twelve, with the inclusion of a semi-finals stage. This inventive approach was introduced to make the quiz more competitive and exciting.
</h1>
<h1 className='text-white font-fira p-6 md:text-[12px] lg:text-[15px] xl:text-[19px] text-justify'>
The University of Mines and Technology (UMaT), known in the competition as “Team Condensate” has won all three organized editions. They became the first ever tertiary institution to win the maiden edition in 2019 as well as the first ever tertiary institution to win the competition on three consecutive occasions, i.e., 2019, 2021 and 2022.</h1>

<h1 className='text-white font-fira p-6 md:text-[12px] lg:text-[15px] xl:text-[19px] text-justify'>The other participating institutions are; 
<br/>
University of Ghana (Propane) – 3x Finalists<br/>
Kwame Nkrumah University of Mines and Technology (Leans Gas) – 2x Finalists<br/>
University of Energy and Natural Resources (LNG) – 1x Finalist<br/>
All Nations University (Pentane)<br/>
Ghana Communication Technology University (Methane)<br/>
HO Technical University (Alkane)<br/>
Kumasi Technical University (Methanol)<br/>
Regional Maritime University (Ethane)<br/>
Takoradi Technical University (LPG)<br/>
Tamale Technical University (Butane)<br/>	
University of Cape Coast (CNG)<br/></h1>

          </span>

        </div>
</div>


           



<Footer/>
</>


)
}


export default Events;