import React from "react";
import HeaderImage from "../assets/contactus.png";
import Navigation from "../navbar";
import Footer from "../footer";
import ContactTab from '../contact-tab';
import { FaPhone } from "react-icons/fa6";
import { FaEnvelope } from "react-icons/fa6";

function Profile() {
  return (
    <>
      <Navigation />
      <div className="relative">
  <div className="absolute inset-0 bg-black bg-opacity-50 flex">
    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
    
    </h1>
  </div>
  <img
    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:h-auto xl:object-cover"
    src={HeaderImage}
    alt="Ghana Gas GPP"
  />
</div>
<div className="flex flex-col items-center">
  <h1 className="font-fira text-dark text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:text-[28px] xl:mx-[270px]">
  <div className="font-fjalla">Ghana Gas Head Office </div><br/>225 Osibisa Close, <br/>Airport West -
    Accra. <br/>P.O Box CT 3686, <br/>Cantonment, <br/>Accra - Ghana.<br/> <br/> <div className="font-fjalla">Contact Us:</div><br/> <FaPhone />
    +233 (0) 302 744200 <br/><FaEnvelope />
    info@ghanagas.com.gh.
  </h1>
  <div className="flex flex-row items-center">

</div>
</div>

      <Footer />
    </>
  );
}

export default Profile;
