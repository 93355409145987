import React from 'react'
import HeaderImage from '../assets/executive.png'
import Navigation from '../navbar'

import Lartey from '../assets/leadership/robert-lartey.png'

import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
  let navigate = useNavigate();

  const Management = () => {
    navigate("/leadership")
  }

  return (

    <>

      <Navigation />

      <div className="relative">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
          src={HeaderImage}
          alt="Ghana Gas GPP"
        />
      </div>

      <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={Management}>
        Executive Management
      </h1>

      <div class="grid md:grid-cols-3 phone:grid-cols-2 gap-4">
        <div class="col-span-1 flex items-start justify-end">
          <img src={Lartey} alt="CEO Image" className='scale-75' />
        </div>
        <div class="col-span-2 flex items-center">
          <div>
            <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Robert Lartey</p>
            <p class="text-black font-fira text-[20px] md:mx-20 phone:mx-10">General Manager, Operations</p>
            <br/>
            <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
            Ing. Lartey is a Chemical Engineer by profession. He started his career as Process Engineer at Tema Oil Refinery (TOR) Limited, Tema - rising to the position of Senior Process Engineer before he resigned to take up the role of Process Engineering Lead on the Western Corridor Gas Infrastructure Development Project (WCGIDP), implemented by the newly incorporated Ghana National Gas Company (Ghana Gas).</p>  <br/>
            <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
            Prior to his appointment as General Manager of Operations, Ing. Lartey served in different capacities in Ghana Gas, including Director of Operations, Manager of the Atuabo Gas Processing Plant and Engineering Manager.</p>  <br/>
            <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10  text-justify leading-snug">
            Ing. Robert Kofi Lartey has professional experience in diverse areas, including chemical/process engineering, project management, procurement management, contract management, cost estimation, oil and gas facilities design, among others.</p>  <br/>
            <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10  text-justify leading-snug">
            Ing. Lartey holds to his credit an Executive Master in Business Administration (EMBA) in Strategic & Project Management from the Paris Graduate School of Management, Paris-France and a B.Sc. (Hons) Chemical Engineering from the Kwame Nkrumah University of Science & Technology, Kumasi, Ghana. 
            </p>  <br/>
            <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10  text-justify leading-snug">
            He is a Professional Engineer, Ghana Institution of Engineering-Ghana and Associate Member of the Institution of Chemical Engineers, UK.</p>
          </div>
        </div>
      </div>
<br/>

      <Footer />
    </>
  )

}

export default Leadership;