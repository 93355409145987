import React from 'react';
import HeaderImage from '../assets/our people.png';
import Leadership from '../assets/executive.png';
import About from '../assets/About us.png';
import { useNavigate } from 'react-router-dom';
import Footer from '../footer'
import Navigation from '../navbar'

function Profile() {
  let navigate = useNavigate();

  const Env = () => {
    navigate("/leadership")
  }

  const HRP = () => {
    navigate("/aboutus")
  }
  return (
    <>
      <Navigation />
      <div className="relative">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
           
          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
          src={HeaderImage}
          alt="Ghana Gas GPP"
        />
      </div>
      <div >

        <h1 className="font-fjalla phone:p-4 phone:text-justify text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
          Our People
        </h1>

        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:text-[28px] xl:mx-[270px]">
          At Ghana Gas, our people are our strength. The dedicated men and women who comprise our workforce are some of the finest employees within corporate Ghana.    </h1>

        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
          We value the diverse skills and capabilities our employees bring to the business. We work continuously to align these skills and capabilities with strategic and operational needs, while maintaining high levels of employee engagement and commitment.    </h1>

        <h1 className=" font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
          To this end, we offer many training and development programmes designed to provide a continuous learning environment, both to gain new skills and to refine existing ones.
        </h1>

        <h1 className=" font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
          As a pioneering company in the gas sub-sector, opportunities for advancement exist far and wide, as do the opportunities to work on a variety of career-defining roles.    </h1>


        <div className="relative hover:cursor-pointer" onClick={Env}>
          <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black bg-opacity-50 flex">
            <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
              Leadership
            </h1>
          </div>
          <img
            className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
            src={Leadership}
            alt="Ghana Gas GPP"
          />
        </div>

        <div className="relative hover:cursor-pointer" onClick={HRP}>
          <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black bg-opacity-50 flex">
            <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
              About Us
            </h1>
          </div>
          <img
            className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
            src={About}
            alt="Ghana Gas GPP"
          />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Profile;
