import React from 'react';
import HeaderImage from '../assets/Local Content.png';
import HR from '../assets/HR Philosophy.jpg';
import Social from '../assets/Social Investment.png';
import Footer from '../footer'
import Navigation from '../navbar'
import { useNavigate } from 'react-router-dom';

function Profile() {

  let navigate = useNavigate();

  const HRP = () => {
    navigate("/hrpolicy")
  }

  const SocialI = () => {
    navigate("/socialinvestments")
  }
  return (
    <>
    <Navigation/>
    <div className="relative">
      <div className="absolute inset-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
         
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
        src={Social}
        alt="Ghana Gas GPP"
      />
    </div>
    <div>

    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Local Content
    </h1>
    <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:text-[28px] xl:mx-[270px]">
    Investment in Human Capital Our contribution to strengthening a nascent industry Empowerment and inclusion of nationals in the management of the Oil and Gas resources Building up national capacity through training, skills & knowledge transfer and employment within the Industry    
    </h1>

    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Our Strategy
        </h1>
    <h1 className="font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:text-[28px] xl:mx-[270px]">
    By sourcing local goods and services and engaging local contractors and consultants we continuously provide opportunities for business and enterprise development. Key areas of focus in our local content policy include knowledge transfer, inclusivity and industry training.
        </h1>

    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Knowledge Transfer    </h1>

    <h1 className=" font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[100px]  xl:leading-snug">
    Ghana Gas has engaged the services of AECOM, a specialist Oil and Gas Project Management Company to provide the key technical and managerial support skills needed to accelerate progress towards Commissioning of the project. The group will also focus on creating a Project Management Division of core project staff and strengthening the team through specialist training and skills transfer programs, which should lead to greater efficiency in Project implementation.    </h1>

    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Inclusivity & Capacity Building    
    </h1>

    <h1 className=" font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[100px]  xl:leading-snug">
    Ghana Gas is in the initial stages of implementing its wider Capacity Building plan to train over 100 Operations and Maintenance technicians in the key critical skills they need to operate the Gas Processing Plant. 60 of the successful trainees will be employed before the commissioning of the plant and this number is expected to increase once the Project expands into its full operational capacity. An estimated 25% of the trainee positions have been set aside for natives from the host Community in the Western Region.    </h1>

    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Education & Training
    </h1>

    <h1 className=" font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[100px]  xl:leading-snug">
    Ghana Gas has also partnered established training Institutions in the Western Region including, Takoradi Polytechnic and Kikam Technical Institute to provide industry training in Operations and Maintenance (O&M), Gas Processing, Transportation and Welding. This will increase the opportunities for local residents to gain employment in the oil and gas service industry. Based on the success and measurable impact of our training programmes and partnership schemes within the community, we hope to further increase investment into educational and training programs by offering scholarships to gifted students. It is our intention once the project is fully commissioned, to implement a solely local hiring policy.    </h1>

    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Our Efforts So Far    </h1>

    <h1 className=" font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[100px]  xl:leading-snug">
    Over 60 different Ghanaian companies have been contracted by the Company to provide corporate services and supplies. Local Consultants have been employed throughout various points of the value chain to provide expertise in the following areas, technical, topographical, geotechnical, geodetic, Environmental Social Impact Assessment and Quality Assurance & Control. The Company has a labor force of over 500 people in skilled and unskilled areas of employment. 100 engineers are currently being shortlisted to undertake training programs in operations and maintenance and maintenance of the Plant.    </h1>

    <div className="relative hover:cursor-pointer" onClick={HRP}>
      <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
          HR Philosophy
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
        src={HR}
        alt="Ghana Gas GPP"
      />
    </div>

    <div className="relative hover:cursor-pointer" onClick={SocialI}>
      <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
          Social Investment
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
        src={HeaderImage}
        alt="Ghana Gas GPP"
      />
    </div>
<Footer/>
    </div>
    </>
  );
}

export default Profile;
