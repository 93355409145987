import React from 'react'
import HeaderImage from '../assets/executive.png'
import Navigation from '../navbar'
import Asmah from '../assets/leadership/sylvia.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const Management = () => {
        navigate("/robertasmah")
    }

    const ExecuManagement = () => {
        navigate("/leadership")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Executive Management
            </h1>

            <div class="grid phone:grid-cols-2 md:grid-cols-3 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Sylvia Assimeng Archer </p>
                        <p class="text-black font-fira text-[20px] md:mx-20 phone:mx-10">General Manager, Company Secretary and Commercial Legal Advisor</p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Sylvia is a Legal Professional and a Notary Public.  Sylvia holds a Bachelor of Laws (Hons.) from the University of Ghana and was admitted to the Ghana Bar in 2000. She holds a Master of Laws degree with specialization in Oil and Gas from the University of Ghana. She also holds a Diploma in Public, Private Partnership, awarded by the Centre for Commercial Law and Financial Regulation, University of Reading. Sylvia is a member of the Association of International Energy Negotiators (AIEN) and the Ghana Bar Association (GBA).</p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Sylvia has significant experience in all facets of law with particular specialization in Commercial/Business law, Intellectual Property law, Banking, Litigation, Arbitration and Mediation and has extensive experience in providing legal advice on Governance and Compliance matters within the private and public sectors. She has consulted and provided comprehensive legal support services for several Ghanaian companies and foreign investors in the areas of drafting and reviewing commercial agreements, incorporation, compliance and regulatory requirements, investments, immigration, insurance and debt recovery.  She has also represented corporate and individual clients in land and commercial litigation in the Superior Courts of Ghana.</p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Sylvia’s role as the Company Secretary of Ghana National Gas Company is to provide timely and accurate legal counsel to the Board and to ensure that guidance is provided to the Board on Corporate Governance matters generally.</p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        As the Commercial Legal Advisor of Ghana Gas, Sylvia provides legal advisory services to the Commercial Operations Department and Management on the commercialisation of the gas resources from Ghana’s oil and gas fields and all gas contract development activities.
                        </p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Sylvia served as an Advisory Board Member for the Ministry of Tourism from June 2017 to December 2020 and is currently a member of the Legal Service Board whose mandate is to spearhead the establishment of an efficient, reputable and well-motivated legal service sector in the country.</p>
                    </div>
                </div>
            </div>
            <br />

            <Footer />
        </>
    )

}

export default Leadership;