import React from 'react'
import Navigation from '../navbar'
import HeaderImage from '../assets/The Environment.png';
import HeadOffice from '../assets/Gas House.jpg'
import GPP from '../assets/Gas Processing Plant.jpg'
import PRMS from '../assets/Prestea Regutatory and Metering Station.jpg'
import SRMS from '../assets/Sekondi Regulatory and Metering Station.jpg'
import TDS from '../assets/Takoradi Distribution Station.jpg'
import EDS from '../assets/Esiama Distribution Station.jpg'
import GasComplex from '../assets/Gas Complex.jpg'
import Footer from '../footer'

function Facility() {
  return (
    <>
      <Navigation />
      <div className="relative">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-center text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
            Facilities
          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
          src={HeaderImage}
          alt="Ghana Gas GPP"
        />
      </div>


      <div className="">
        {/* First Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-4 mt-[10rem] md:ml-[120px] phone:space-x-4">
          <div className='flex md:flex-row'>
            <div className='flex flex-col'>
              <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[0.1rem] md:space-x-10 phone:space-y-3 md:space-y-0 phone:p-5'>
                <div class="relative">
                  <img src={HeadOffice} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover " />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Gas House</p>
                      <p class="text-white font-fira font-bold md:text-[15px] phone:text-[12px]">Head Office - Accra</p>

                    </div>
                  </div>
                </div>
              </div>
              <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                <div class="relative">
                  <img src={GPP} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">GPP</p>
                      <p class="text-white font-fira font-bold md:text-[15px]">Station - Atuabo</p></div>
                  </div>
                </div>


                <div class="relative">
                  <img src={GasComplex} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Gas Complex</p>
                      <p class="text-white font-fira font-bold md:text-[15px]">Office - AB Bokazo</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                <div class="relative">
                  <img src={SRMS} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">SRMS</p>
                      <p class="text-white font-fira font-bold md:text-[15px]">Station - Takoradi</p></div>
                  </div>
                </div>


                <div class="relative">
                  <img src={EDS} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">EDS</p>
                      <p class="text-white font-fira font-bold md:text-[15px]">Station - Essiama</p>
                    </div>
                  </div>
                </div>
              </div>


              <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                <div class="relative">
                  <img src={PRMS} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">PRMS</p>
                      <p class="text-white font-fira font-bold md:text-[15px]">Station - Prestea</p>
                    </div>
                  </div>
                </div>


                <div class="relative">
                  <img src={TDS} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">TDS</p>
                      <p class="text-white font-fira font-bold md:text-[15px]">Station - Takoradi</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <span className='md:bg-black md:h-full phone:bg-white md:w-[546px] md:ml-[12rem] phone:ml-[30px] phone:w-[400px]'>
            <h1 className='md:text-white phone:text-black font-fira p-6 md:text-[12px] phone:text-[18px] lg:text-[15px] xl:text-[19px] text-justify'>
              <b>Gas House</b><br />
              The Gas House is the cutting-edge administrative building of Ghana National Gas Company Limited. It serves as the headquarters of the company and it is located at Dzorwulu in Accra.
              <br /><br />

              <b> Gas Processing Plant (GPP)</b><br />
              The Gas Processing Plant (GPP) is one of the most crucial facilities of Ghana National Gas Company located at Atuabo in the Western Region of Ghana. The gas plant comprises of: Inlet separation, gas chilling/low temperature separation and de-ethanization, liquids fractionation, ethylene glycol injection and regeneration, instrument air and nitrogen systems, a heat medium system, flare and closed drain systems, plant electrical and control systems, and piping. The reception facilities (GPP) at Atuabo, are designed to receive 150MMSCFD feed gas through 12” transportation line from Kwame Nkrumah and John Evans Atta Mills FPSO which process Oil and Associated Gas from Jubilee and TEN Fields and comprises of three parts: safety system of the raw gas transportation pipeline, pig receiver skid used periodically for scraping or inspection of the transportation pipeline and raw gas measurement and monitoring system. Raw gas from 12” transportation pipeline arrives at the reception facilities in dense phase at operating pressure of 130 to 205barg (design pressure is 238 barg) and operation temperature of 20℃ to 30℃ (design temperature is 0℃ to 70℃).
              In case of overpressure, leakage or fire the emergency shutdown valve ESDV-01-001-GP, installed upstream of the barred tee, isolates the 12” gas transportation pipeline towards the gas processing facilities. For offshore pipeline depressurization, a 6” line with manual relief valves are installed at the point of upstream of the ESDV and the relief gas will be sent to high pressure flare header. The gas processing plant is designed to achieve marketable products that meet desired product specifications: Sales gas, LPG, Condensate and Isopetane. The end products are used as follows:
              <br />
              • Sales gas from the processing plant is routed through AIS onshore gas pipeline via EDS to Takoradi Distribution Station and Prestea Regulating and Metering Station
              <br />
              • LPG and Condensate is consumed locally or exported by pipelines to the loading Gantry and Export System at Anochie.
              <br /><br />
              <b> Gas Complex</b><br />
              The office complex is commonly referred to as Gas Complex. The Gas Complex is the state of the art edifice at Atuabo in the western region. Its primary purpose is to provide a befitting and adequate office space for the hardworking staff at the operational sites.
              <br /><br />
              <b>Sekondi Regulating and Metering Station (SRMS)</b><br />
              SRMS is the terminal station in Sekondi and has an 8KM onshore pipeline from TDS. SRMS solely serves the power barge i.e. Karpowership. The gas flows from TDS to DVS2 where there is a T-off to Twin City Energy. The gas continues from that point to Double Block and Bleed (DBB) valve station and then from there to SRMS. This makes up the 8KM onshore pipeline network from TDS to SRMS.<br />
              The infrastructure is required to transport sales gas from the Takoradi Distribution Station (TDS) to the Twin City Energy via the Distribution Valve Station (DVS2) and Karpowership via the Sekondi Regulating and Metering Station (SRMS). The 8 km onshore pipeline is connected downstream of the gas heaters at TDS.
              <br /><br />
              <b> Esiama Distribution Station (EDS)</b><br />
              The metered gas from AIS onto the onshore pipeline passes through an intermediate distribution station EDS in Esiama. Through manifold point, another 20-inch, 78 km pipeline transport gas from the EDS station to Prestea.  There are two block valve stations, BLV1 and BLV2 along the pipeline from AIS to Takoradi. Within the Block Valve Stations are local vent stacks for depressurization. The function of the BLV station is to isolate any segment of the pipeline for maintenance work or isolate a leak or rupture. Also, two block valve stations BLV3 and BLV4 are located along the pipeline from EDS to Prestea.
              <br /><br />
              <b>Prestea Regulating and Metering Station</b><br />
              PRMS is the terminal station to the mining enclave. The station is to provide cheaper natural gas to the mining companies in the enclave. Lean gas is transported from the 75KM lateral pipeline from EDS to PRMS. PRMS receives the lean gas, condition, monitor and control it and then transports it from the PRMS to Goldfields Ghana Limited in Tarkwa and Damang mines.<br /><br />

              <b> Takoradi Distribution Station</b><br />
              The main terminal station at Takoradi is the Takoradi Distribution Station (TDS), which serves the Aboadze Thermal Power Enclave and other consumers. The Thermal Power Enclave consists of the VRA T1 and T2 Power plants, Twin City Energy and Karpowership. The sales gas at the TDS feeds these plants for power generation which is transmitted to the national grid. The other consumers are the ceramic producing companies (Keda Ghana, Wangkang, Jintao and Marcopolo) and other future potential clients who have an interest in taking the sales gas for industrial purposes.
              <br />
              The gas flows through the emergency shutdown valve (ESDV22012) to the main onshore pipeline to Takoradi via Block Valve Station 1(BLV1) and Block Valve Station 2 (BLV2). The main onshore transmission network (pipeline) from AIS to TDS is about 110.9 Km.
            </h1>
          </span>

        </div>
      </div>
      <div className='mt-10'>
        <Footer />
      </div>

    </>
  )
}

export default Facility;
