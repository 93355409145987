import React from 'react'
import HeaderImage from '../assets/Board.png'
import Navigation from '../navbar'
import Asmah from '../assets/board/Owusu.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const ExecuManagement = () => {
        navigate("/board")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Board of Directors
            </h1>

            <div class="grid phone:grid-cols-2 md:grid-cols-3 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Nana Owusu Ansah Ababio</p>
                        <p class="text-black font-fira text-[20px] md:mx-20 phone:mx-10">Board Member</p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Nana Owusu Ansah Ababio is a Traditional Ruler in the Asante Mampong Traditional Area. He holds a master's degree in German and English literature from Heidelberg University, Germany and a diploma in Russian and Political Economy from Moscow State University, USSR.                        </p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Nana is a linguistic scholar who taught German language, and African and Minority-American History to American military personnel in Mannheim, Germany. On his return to Ghana, Nana served briefly as an officer at the Economic Relations Division of the Ministry of Foreign Affairs. He later went into private business, setting up three companies of which he was the Executive Chairman. The Kanolive (Gambia) Limited dealing in educational materials in the Gambia and representing Afram Publications in West Africa. The other two are Tesito Limited dealing in the production and distribution of industrial protective clothing in Accra and Oguapem Company Limited, dealers in Timber Products.                        </p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Nana is a Board Member of the Asante Mampong Babies Home, an orphanage.
                        </p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        For recreation, he takes a keen interest in chess and scrabble.                        </p><br/>
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Nana is a Member of the Board of the Ghana National Gas Company and serves on the Finance & Commercial Committee, Corporate Social Responsibility Committee and the HR & Governance Committee.                        </p>
                        <br />
                    </div>
                </div>
            </div>
            <br />


            <Footer />
        </>
    )

}

export default Leadership;