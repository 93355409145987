import React from 'react' 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutUs from './pages/aboutus'
import Homepage from './pages/homepage';
import Profile from './pages/profile'
import Infrastructure from './pages/infrastructure';
import Mission from './pages/mission'
import CorporateValues from './pages/corporatevalues'
import OurPeople from './pages/ourpeople'
import CorporateObjectives from './pages/corporateobjectives'
import Environment from './pages/theenvironment'
import HSEPolicy from './pages/hsepolicy'
import HRPolicy from './pages/hrpolicy'
import LocalContent from './pages/localcontent'
import SocialInvestment from './pages/socialinvestment'
import RTI from './pages/righttoinformation'
import Operations from './pages/operations'
import Strategy from './pages/strategy'
import Mandate from './pages/mandate'
import KeyObjectives from './pages/keyobjectives'
import BestPractices from './pages/bestpractices'
import Stakeholder from './pages/stakeholders'
import Tariff from './pages/tariff'
import Facilities from './pages/facilities';
import Leadership from './pages/leadership';
import Board from './pages/board';
import History from './pages/history';
import Mentorship from './pages/mentorship'
import Media from './pages/media'
import Careers from './pages/careers';
import Community from './pages/community'
import Sample from './pages/sample'
import Events from './pages/events'
import ContactUs from './pages/contactus'
import Testing from './pages/testing'
import Scholarship from './pages/scholarship'
import Certifications from './pages/certifications'
import Lartey from './leaders/robertlartey'
import Asmah from './leaders/robertasmah'
import Natasha from './leaders/natashanylander'
import Rene from './leaders/renenelson'
import Jeremiah from './leaders/jeremiahaddotwum'
import Alma from './leaders/almamensah'
import Jomo from './leaders/stephenjomo'
import Benjamin from './leaders/benjaminbaidoo'
import NanaYaa from './leaders/nanayaa'
import Sylvia from './leaders/sylviaarcher'
import Abraham from './board/abrahamaidooh'
import Awulae from './board/awulae'
import BenAsante from './board/benasante'
import Delphine from './board/delphine'
import John from './board/johndarko'
import Ken from './board/kennedyagyapong'
import NanaAdwoa from './board/nanaadwoa'
import NanaOwusu from './board/nanaowusu'
import Sumani from './board/stephensumani'
import OwusuBempah from './leaders/owusubempah'
import AbrahamMensah from './leaders/abrahammensah'
import Albert from './leaders/albertnanevie'
import Augustina from './leaders/augustina'
import Management from './pages/management'


const App = () => {
  return (


      <div>
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Homepage />} />
                  <Route path="/home" element={<Homepage />} />                   
                  <Route path="/aboutus" element={<AboutUs />} /> 
                  <Route path="/profile" element={<Profile />} /> 
                  <Route path="/infrastructure" element={<Infrastructure />} /> 
                  <Route path="/mission" element={<Mission />} />                   
                  <Route path="/ourpeople" element={<OurPeople />} /> 
                  <Route path="/corporateobjectives" element={<CorporateObjectives />} /> 
                  <Route path="/theenvironment" element={<Environment />} /> 
                  <Route path="/hsepolicy" element={<HSEPolicy />} />                   
                  <Route path="/hrpolicy" element={<HRPolicy />} /> 
                  <Route path="/localcontent" element={<LocalContent />} /> 
                  <Route path="/socialinvestment" element={<SocialInvestment />} /> 
                  <Route path="/operations" element={<Operations />} />                   
                  <Route path="/strategy" element={<Strategy />} /> 
                  <Route path="/mandate" element={<Mandate />} /> 
                  <Route path="/bestpractices" element={<BestPractices />} /> 
                  <Route path="/stakeholders" element={<Stakeholder />} />                   
                  <Route path="/tariff" element={<Tariff />} /> 
                  <Route path="/mentorship" element={<Mentorship />} /> 
                  <Route path="/corporatevalues" element={<CorporateValues />} /> 
                  <Route path="/righttoinformation" element={<RTI />} />                   
                  <Route path="/keyobjectives" element={<KeyObjectives />} />                   
                  <Route path="/facilities" element={<Facilities />} />                   
                  <Route path="/leadership" element={<Leadership />} />                   
                  <Route path="/board" element={<Board/>} />                   
                  <Route path="/history" element={<History/>} />                   
                  <Route path="/careers" element={<Careers/>} />                   
                  <Route path="/media" element={<Media/>} />                   
                  <Route path="/community" element={<Community/>} />                   
                  <Route path="/sample" element={<Sample/>} />                   
                  <Route path="/events" element={<Events/>} />                   
                  <Route path="/contactus" element={<ContactUs/>} />                   
                  <Route path="/testing" element={<Testing/>} />                   
                  <Route path="/scholarship" element={<Scholarship/>} />                   
                  <Route path="/certifications" element={<Certifications/>} />  
                  <Route path="/robertlartey" element={<Lartey/>} />                   
                  <Route path="/robertasmah" element={<Asmah/>} />                   
                  <Route path="/natashanylander" element={<Natasha/>} />                   
                  <Route path="/renenelson" element={<Rene/>} />                   
                  <Route path="/jeremiahaddotwum" element={<Jeremiah/>} />                   
                  <Route path="/almamensah" element={<Alma/>} />                   
                  <Route path="/stephenjomo" element={<Jomo/>} />                   
                  <Route path="/benjaminbaidoo" element={<Benjamin/>} />                   
                  <Route path="/nanayaa" element={<NanaYaa/>} />  
                  <Route path="/sylviaarcher" element={<Sylvia/>} />                   
                  <Route path="/abrahamaidooh" element={<Abraham/>} />                   
                  <Route path="/awulae" element={<Awulae/>} />                   
                  <Route path="/benasante" element={<BenAsante/>} />                   
                  <Route path="/delphine" element={<Delphine/>} />                   
                  <Route path="/kennedyagyapong" element={<Ken/>} />                   
                  <Route path="/nanaadwoa" element={<NanaAdwoa/>} />                   
                  <Route path="/nanaowusu" element={<NanaOwusu/>} />                   
                  <Route path="/stephensumani" element={<Sumani/>} />  
                  <Route path="/johndarko" element={<John/>} />  
                  <Route path="/owusubempah" element={<OwusuBempah/>} />  
                  <Route path="/albert" element={<Albert/>} />  
                  <Route path="/abrahammensah" element={<AbrahamMensah/>} />  
                  <Route path="/augustina" element={<Augustina/>} />  
                  <Route path="/management" element={<Management/>} />  

              </Routes>
          </BrowserRouter>
          {
              
          }

          </div>
             )
}

export default App;
