import React from 'react';
import HeaderImage from '../assets/Local Content.png';
import Social from '../assets/Social Investment.png';
import Scholarship from '../assets/Mentorship.png';
import Footer from '../footer'
import Navigation from '../navbar'
import { useNavigate } from 'react-router-dom';

function Profile() {
  let navigate = useNavigate();

  const LocalC = () => {
    navigate("/localcontent")
  }

  const Mentor = () => {
    navigate("/mentorship")
  }

  const Commu = () => {
    navigate("/community")
  }
  return (
    <>
    <Navigation/>
    <div className="relative">
      <div className="absolute inset-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
      
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
        src={HeaderImage}
        alt="Ghana Gas GPP"
      />
    </div>
    <div>

    <h1 className="font-fjalla text-gasblue phone:p-4 phone:text-justify  text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Corporate Social Investment
    </h1>

    <h1 className="font-fjalla text-gasblue  phone:p-4 phone:text-justify text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Background
    </h1>

    <h1 className=" font-fira text-dark  phone:p-4 phone:text-justify text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
    Ghana National Gas Company’s Social Investment policy is aligned with Sustainable Development Goals 3,4, and 6, which addresses: Good health and well-being, Quality Education, Clean water and Sanitation respectively. <br/><br/>
It is on this backdrop that the company through the CR/CSR Division has developed strategies which seeks to improve the socio-economic and environmental well-being of the project-affected communities. <br/><br/>
As a national asset, GNGC’s social investments go beyond its operational areas to cover all the sixteen (16) regions of the country.  </h1>

<h1 className="font-fjalla  phone:p-4 phone:text-justify text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
Pillars of Ghana National Gas Company (GNGC) Social Investments    </h1> 
<h1 className=" font-fira  phone:p-4 phone:text-justify text-dark text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
Pillars of GNGC Social investments span across:<br/><br/>
<div className='ml-12'>
•Educational Infrastructure<br/>
•Health Care Facilities<br/>
•Water and Sanitation<br/>
•Road Infrastructure<br/>
•Support for Security Services<br/>
•Sports (Construction of Astro turfs, Hockey Pitches and Sponsorship)<br/>
•Livelihood / Skills Training Programmes<br/>
•Social Development Projects<br/>
</div>
</h1>
<h1 className=" font-fira text-dark  phone:p-4 phone:text-justify text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
Over six thousand people within project affected communities (PACs) in Western Region were registered onto the National Health Insurance Scheme(NHIS). </h1>

<h1 className=" font-fira text-dark  phone:p-4 phone:text-justify text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
Donation to Accra Psychiatric Hospital, Child Health Centre of the Korle Bu Teaching Hospital, payment for surgeries for children with multiple scoliosis at FOCOS Orthopedic Hospital through Miss Ghana Foundation. </h1>

<h1 className=" font-fira text-dark  phone:p-4 phone:text-justify text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
In addition, over two hundred wheelchairs were presented to people with disability through the Henry Djaba Foundation. </h1>

<h1 className=" font-fira text-dark  phone:p-4 phone:text-justify text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
Furthermore, the company has supported the Nzulezu Community Basic School and Takoradi Catholic Special Vocational School with education materials. </h1>

<h1 className=" font-fira text-dark  phone:p-4 phone:text-justify text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
Apart from supporting the National Covid19 Fund with a million Ghana Cedis (1,000,000), the company also donated logistics and an amount of one hundred and twenty thousand cedis (120,000) to the Ellembelle district, Jomoro and the Nzema East Municipality.  </h1>

<h1 className=" font-fira text-dark  phone:p-4 phone:text-justify text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
GNGC is committed to improving the socio-economic livelihoods of Project Affected Communities in particular and Ghana at large through strategic corporate social investments.</h1>


<button class="transition ease-in-out mb-10 rounded-[5px] border-[1px] border-gasblue hover:bg-white duration-700 hover:text-black bg-gasblue px-4 text-white font-fira text-[28px] xl:mx-[270px]" onClick={Commu}>Click here to view projects</button>





    <div className="relative hover:cursor-pointer" onClick={LocalC}>
      <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
          Local Content
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
        src={Social}
        alt="Ghana Gas GPP"
      />
    </div>

    <div className="relative hover:cursor-pointer" onClick={Mentor}>
      <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
          Mentorship
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
        src={Scholarship}
        alt="Ghana Gas GPP"
      />
    </div>
<Footer/>
    </div>
    </>
  );
}

export default Profile;
