import React from 'react'
import HeaderImage from '../assets/executive.png'
import Navigation from '../navbar'
import Asmah from '../assets/leadership/benjamin.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const Management = () => {
        navigate("/robertasmah")
    }

    const ExecuManagement = () => {
        navigate("/leadership")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Executive Management
            </h1>

            <div class="grid md:grid-cols-3 phone:grid-cols-2 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Benjamin Baidoo </p>
                        <p class="text-black font-fira text-[20px] md:mx-20 phone:mx-10">General Manager, Procurement and Logistics</p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Mr. Benjamin Baidoo is a Chartered Procurement and Supply Chain Management Professional with over 23 years’ progressive experience in procurement and supply chain practice, an astute practitioner and academician.                             
                        </p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        He has worked with institutions like; the World Health Organisation (WHO), the Coca-Cola Bottling Company of Ghana Limited, National Accreditation Board, Nestle Ghana Limited, the Ghana Ports & Harbours Authority (GPHA), Ghana National Petroleum Corporation (GNPC) and Ghana Civil Aviation Authority (GCAA).</p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Mr. Baidoo was awarded with the CIPS Pan African Procurement & SOEPF AAPP (2015), Chartered Institute of Procurement & Supply award (2016), and was adjudged by the Ghana Procurement & Supply Chain Awards as one of ‘Ghana’s Top 20 Procurement Leaders’. </p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Mr. Baidoo is a chartered member of the Ghana Institute of Procurement & Supply (MGIPS) and the Chartered Institute of Procurement & Supply, UK (MCIPS), a Certified International Procurement Professional (CIPP) and a life member of the International Academy of Project Management (MIAPM). He holds an MBA in General Management and an MSc in Supply Chain Management; and also pursued a Master of Laws Degree in International Business Law with the University of Liverpool, UK. He is the current Vice President of the Ghana Institute of Procurement & Supply (GIPS).                         </p>  <br />

                    </div>
                </div>
            </div>
            <br />

            <Footer />
        </>
    )

}

export default Leadership;