import React from 'react';
import HeaderImage from '../assets/Corporate Objectives.png';
import Environment from '../assets/The Environment.png';
import HSE from '../assets/SHE 2.jpg';
import Footer from '../footer'
import Navigation from '../navbar'
import { useNavigate } from 'react-router-dom';

function Profile() {
  let navigate = useNavigate();

  const Env = () => {
    navigate("/theenvironment")
  }

  const HSEPolicy = () => {
    navigate("/hsepolicy")
  }
  return (
    <>
    <Navigation/>
    <div className="relative">
      <div className="absolute inset-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
       
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
        src={HeaderImage}
        alt="Ghana Gas GPP"
      />
    </div>
    <div>

    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Corporate Objectives
    </h1>


    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Maximize Profitability
    </h1>
    <h1 className="font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:text-[28px] xl:mx-[270px]">
    The company shall efficiently deploy its resources and capital to attain profitability and to generate surpluses that can be deployed for rapid development of the gas industry in Ghana.    </h1>

    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Become an Established and Reliable Supplier of Natural Gas Products
    </h1>

    <h1 className=" font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
    As a pioneering company in the Ghanaian economy, the company will strive to establish itself as a reliable supplier by deploying the best technology and technical management methods to ensure availability of its resources to the service of Ghanaian industry.    </h1>

    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Efficiently Meet Ghana's Energy Demand
    </h1>

    <h1 className=" font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
    Ghana's ability to continue to develop largely depends on the availability of low cost energy for powering industries; one of GNGC's business functions is to augment the country's hydroelectric power supply by providing natural gas to the thermal segment of the power industry.
    </h1>

    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Contribute to the Development of Ghana's Economy
        </h1>

    <h1 className=" font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
    Through the production of natural gas liquids, the company is uniquely positioned to spawn the development of new petrochemical industries that will utilize its products as feedstock, and thereby expand Ghana's economy.
    </h1>

    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Safety and Protection of the Environment
    </h1>

    <h1 className=" font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
    The company shall strive to use the strictest environmental strategies and safety methods to ensure that its business success is not negatively offset by degradation of the environment or undue risk to personnel. This will be attained through the development of a world-class safety culture that will be imbued in all the ranks of the corporate body and thus ensure the protection of life and the environment within which it operates.
    </h1>

    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Development of a World Class Workforce
    </h1>

    <h1 className=" font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
    The company will leverage Ghana's traditional strength in academia to develop a highly trained and motivated world class workforce in short order, which will play a pivotal role in accelerating the development of Ghana's economy. This will be achieved through the development and adaption of training programs and the recruitment of experienced Ghanaian professionals to support the company.    </h1>

    
    <div className="relative hover:cursor-pointer" onClick={Env}>
      <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
          The Environment
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
        src={Environment}
        alt="Ghana Gas GPP"
      />
    </div>

    <div className="relative hover:cursor-pointer" onClick={HSEPolicy}>
      <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
          HSE Policy
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
        src={HSE}
        alt="Ghana Gas GPP"
      />
    </div>
<Footer/>
    </div>
    </>
  );
}

export default Profile;
