import React from "react";
import Nav from '../navbar'
import HeaderImage from '../assets/careers.png'
import Tariff from '../assets/tarrif.jpg'
import Profile from "../assets/Profile.jpg";
import { useNavigate } from "react-router-dom";


function Careers(){
  let navigate = useNavigate();

  const Tar = () => {
    navigate("/tariff")
  }

  const Med = () => {
    navigate("/media")
  }
    return(

<>
<Nav/>

<div className="relative">
      <div className="absolute inset-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
       
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
        src={HeaderImage}
        alt="Ghana Gas GPP"
      />
    </div>
    <div>

    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Careers at Ghana Gas
    </h1>
    <h1 className="font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:text-[28px] xl:mx-[270px]">
    Ghana Gas has a strong presence in all aspects of the oil and gas industry. As one of the largest Ghanaian-based energy companies, Ghana Gas produces natural gas and other products essential for the progress of Ghana.    </h1>
    
    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Who We Hire
    </h1>
    <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Engineers and Technicians
    </h1>
    <h1 className=" font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
<div className='ml-12'>
•Mechanical<br/>
•Civil<br/>
•Electrical<br/>
•Geomatics<br/>
•Geological<br/>
•Petrochemical<br/>
•Pipeline<br/>
•Materials Engineering<br/>
</div>
</h1>

<h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
Projects
    </h1>
    <h1 className=" font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
<div className='ml-12'>
•Project Management<br/>
•Procurement and Logistics<br/>
•Supply Chain<br/>
</div>
</h1>
<h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
Commercial
    </h1>
    <h1 className=" font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
<div className='ml-12'>
•Economics<br/>
•Marketing<br/>
</div>
</h1>
<h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
Support Services and Administration
    </h1>
    <h1 className=" font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
<div className='ml-12'>
•Communication<br/>
•Human Resource<br/>
•Administration<br/>
•Information Technology Services<br/>
•Health and Safety<br/>
</div>
</h1>
<h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Internships
    </h1>
    <h1 className="font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:text-[28px] xl:mx-[270px]">
    Ghana Gas' internships are available for outstanding university students, which gives you the opportunity to:    </h1>

    <h1 className=" font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:mb-[50px]  xl:leading-snug">
<div className='ml-12'>
•Gain hands-on experience by participating in live projects<br/>
•Explore skills under the supervision and guidance of experienced professionals<br/>
•Use our internship program as a gateway to explore job opportunities with Ghana Gas after graduation<br/>
</div>
</h1>

<h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
Career Development Support    </h1>
    <h1 className="font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:text-[28px] xl:mx-[270px]">
    When you join Ghana Gas, you’ll find a workplace committed to your professional development. You’ll receive support and tools to create your own career path, including education assistance support.     </h1>

    <div className="relative hover:cursor-pointer" onClick={Tar}>
      <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black bg-opacity-50 flex">
        <h1 className="text-white font-fjalla text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
          Tariff
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
        src={Tariff}
        alt="Ghana Gas GPP"
      />
    </div>

    <div className="relative hover:cursor-pointer" onClick={Med}>
      <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
        Media
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
        src={Profile}
        alt="Ghana Gas GPP"
      />
    </div>
    </div>
</>
)
}


export default Careers;