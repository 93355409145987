import React from 'react'
import HeaderImage from '../assets/executive.png'
import Navigation from '../navbar'
import Asmah from '../assets/leadership/jomo.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const Management = () => {
        navigate("/robertasmah")
    }

    const ExecuManagement = () => {
        navigate("/leadership")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Executive Management
            </h1>

            <div class="grid phone:grid-cols-2 md:grid-cols-3 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10 ">Stephen Borteye Jomo </p>
                        <p class="text-black font-fira text-[20px] md:mx-20 phone:mx-10 ">General Manager, Commercial Operations</p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10  text-justify leading-snug">
                        Mr. Stephen Borteye Jomo, is a multidisciplinary professional with over 16 years of experience within and outside the oil and gas industry. </p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10  text-justify leading-snug">
                        He has worked in various commercial, technical and operational roles, especially in the gas industry over the past 13 years and gained expertise in Gas Control Centre Operations, Gas Commercialization, including Gas Contract Management, Contract Drafting & Negotiation, Gas Accounting, Business and Project Development, Project Financing, among others. </p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10  text-justify leading-snug">
                        He worked at West African Gas Pipeline Company (WAPCo) for 8 years in various capacities and departed as Supervisor of the Gas Control Centre/Scheduler Volumetric Analyst. He was responsible for Gas Control Room Operations, commercial and operational management interface with NGas gas, Buyers and Sellers on the West African Gas Pipeline (WAGP).</p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10  text-justify leading-snug">
                        He also worked with Unilever Ghana Limited, Aluworks Ghana Limited, Ghana Textile Manufacturing Company Limited (GTP), among others.                        </p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        He holds Law degree (L.L.B) from Mount Crest University College, Commonwealth Executive MBA from KNUST, BSc in Operations and Project Management from GIMPA, Higher National Diploma (HND) in Mechanical Engineering from Accra Polytechnic (now Accra Technical University), among others.
</p>
                    </div>
                </div>
            </div>
            <br />

            <Footer />
        </>
    )

}

export default Leadership;