import React from 'react';
import Navigation from '../navbar'
import Footer from '../footer';
import BoardImage from '../assets/leadership.jpg'
import CEO from '../assets/leadership/ceo.png'
import Ken from '../assets/board/Ken.png'
import Awulae from '../assets/board/Awulae.png'
import Nayina from '../assets/board/Sumani.png'
import Delphine from '../assets/board/Delphine.png'
import Abraham from '../assets/board/Osei.png'
import Owusu from '../assets/board/Owusu.png'
import Nana from '../assets/board/Asiaa.png'
import John from '../assets/board/John.png'
import People from '../assets/leadership.jpg'
import { useNavigate } from 'react-router-dom';


function Board() {
  let navigate = useNavigate();

  const BoardDirector = () => {
    navigate("/leadership")
  }

  const AbrahamA = () => {
    navigate("/abrahamaidooh")
  }
  const Amihere = () => {
    navigate("/awulae")
  }
  const BenAsante = () => {
    navigate("/benasante")
  }
  const Delph = () => {
    navigate("/delphine")
  }
  const JohnD = () => {
    navigate("/johndarko")
  }

  const OurPeople = () => {
    navigate("/management")
  }
  const KenAgy = () => {
    navigate("/kennedyagyapong")
  }
  const NanaAdwoa = () => {
    navigate("/nanaadwoa")
  }
  const NanaOwusu = () => {
    navigate("/nanaowusu")
  }
  const StephenSum = () => {
    navigate("/stephensumani")
  }
  return (
    <>
      <Navigation />
      <div className="relative">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
          src={BoardImage}
          alt="Ghana Gas GPP"
        />
      </div>

      <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
        Board of Directors
      </h1>

    <div class="grid md:grid-cols-3 gap-4 phone: grid-cols-2">
        <div class="col-span-1 flex items-center justify-end">
          <img src={Ken} alt="CEO Image" className='scale-75' />
        </div>
        <div class="col-span-2 flex items-center hover:cursor-pointer" onClick={KenAgy}>
          <div>
            <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Kennedy Ohene Agyapong</p>
            <p class="text-black font-fira text-[20px] md:ms-20 phone:mx-10">Board Chairman</p>
            <p class="text-black font-fira md:text-[19px] phone:text-[15px] phone:mx-10 md:mx-20 text-justify leading-snug">
            Mr. Kennedy Ohene Agyapong, MP is a Ghanaian politician who represents Assin Central in Parliament for the New Patriotic Party.
            He is currently the Chairman of the Parliament’s Defense and Interior Committee and the Board Chairman of Ghana National Gas Company Limited.
          </p>
          <br/>
          <p class="text-black font-fira md:text-[16px] md:mx-20 phone:text-[12px] phone:mx-10">Read More</p>
          </div>
        </div>
      </div>



      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={BenAsante}>
          <img src={CEO} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Dr. Ben  K.D. Asante</span>
          <span className='font-fira text-[17px]'>Chief Executive Officer</span>
        </div>

        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={Amihere}>
          <img src={Awulae} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Awulae Amihere Kpanyinli III</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>

      </div>      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={StephenSum}>
          <img src={Nayina} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Mr. Stephen Sumani Nayina</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={JohnD}>
          <img src={John} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Mr. John Darko</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>
      </div>

      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={Delph}>
          <img src={Delphine} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Mad. Delphine Dogbegah</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={NanaAdwoa}>
          <img src={Nana} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Nana Adwoa Asiaa Benneh Beyeeman I</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>
      </div>
      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={NanaOwusu}>
          <img src={Owusu} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Nana Owusu Ansah Ababio</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={AbrahamA}>
          <img src={Abraham} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Mr. Abraham Osei Aidooh</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>
      </div>
<br/>
<br />
<div className="relative hover:cursor-pointer" onClick={BoardDirector}>
        <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
            Executive Management
          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
          src={BoardImage}
          alt="Ghana Gas GPP"
        />
      </div>

      <div className="relative hover:cursor-pointer" onClick={OurPeople}>
        <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
            Management
          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
          src={People}
          alt="Ghana Gas GPP"
        />
      </div>

      <Footer />

    </>
  )
}

export default Board;