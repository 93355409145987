import React from "react";
import Navigation from "../navbar";
import Footer from "../footer";
import HeaderImage from "../assets/operations.png";
import Slider from "../operationslider";
import MobileSlider from '../operationsslider-mobile'
import OperationsOne from "../assets/operations-1.png";
import OperationsTwo from "../assets/operations-content.png";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Information from "../assets/rti.jpg"
import Strategic from "../assets/final-strategy.jpg"

function Operations() {
  let navigate = useNavigate();

  const RTI = () => {
    navigate("/righttoinformation")
  }

  const Stra = () => {
    navigate("/strategy")
  }


  return (
    <>
      <Navigation />
      <div className="relative">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
       
          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
          src={HeaderImage}
          alt="Ghana Gas GPP"
        />
      </div>

      <h1 className="font-fira text-dark text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
        The Operations Division (OD) is one of the most essential division in
        Ghana National Gas Company (Ghana Gas/ GNGC). OD is made up of the
        following departments:
      </h1>
      <div class="phone:hidden md:hidden lg:block ">
      <Slider  />
      </div>

<div class="phone:block md:block lg:hidden">
  <MobileSlider/>
</div>

      <div class="flex flex-wrap md:mt-[55rem] mt-[60rem] phone:mt-[3rem] 4K-screen:mt-[73rem]">
        <div class="w-full sm:w-1/2">
          <img
            src={OperationsOne}
            alt="Image"
            class="w-full xl:h-[1000px] object-cover"
          />
        </div>

        <div class="w-full sm:w-1/2 flex items-center justify-center">
          <div className="bg-gasblue h-full">
            <div className="flex flex-col font-fira space-y-5 md:text-[18px] phone:text-[15px] md:p-20 phone:px-10 text-white">
              <p class="text-justify">
              Ghana National Gas Company’s (Ghana Gas) operations focus on the utilization of associated natural gas resources from offshore oil production to generate power, provide subsidized gas for non-power industrial use, as well as the supply of clean energy for domestic use. With Ghana Gas’ new mandate as an Integrated Gas Company (Aggregator, Shipper, and System Operator), the key operations of Ghana Gas include:
              </p>
              <p class="text-justify">
              Gas Gathering: As Ghana Gas transitions into an integrated gas company, Ghana Gas is now in a position to collect raw gas from Floating Processing Storage and Offloading (FPSO) Vessel and transport via pipelines to our Atuabo Gas Processing Plant (GPP) for processing.
              </p>
              <p class="text-justify">
              Gas Processing: Ghana Gas operates the Atuabo Gas Processing Plant (GPP) located at Atuabo in the Ellembelle district of the Western Region of Ghana. The GPP receives raw natural gas from offshore fields, primarily the Jubilee and TEN fields, and processes it to remove impurities such as water, and other contaminants. Raw gas is conditioned at the GPP and separated into various products; Lean gas, Liquefied Petroleum Gas (LPG) and Condensate.
              </p>
              <p class="text-justify">
              Gas Transportation: Once the natural gas is processed into Lean (Sales) gas, it is transported through pipelines to consumers, including power plants, and industries. Ghana Gas operates a pipeline network that spans across the Western Region and connects to major natural gas demand centers in the country.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap mb-[5rem]">
        <div class="w-full sm:w-1/2 flex items-center justify-center">
          <div className="bg-gasblue h-full">
            <div className="flex flex-col font-fira phone:space-y-5 md:space-y-5 md:text-[18px] phone:text-[15px] md:p-20 phone:px-10 text-white">
              <p class="text-justify">
              Power Generation: Ghana Gas plays a crucial role in supporting the country's power sector by supplying lean gas to Volta River Authority (VRA), Karpowership and Amandi. The gas is used as a fuel source for power generation, contributing to a more reliable, cleaner and affordable power supply. This helps reduce reliance on expensive imported fuels such as LCO (Light Crude Oil) and supports Ghana's goal of increasing access to electricity across the country.
              </p>
              <p class="text-justify">
              Non-Power Sector: Ghana Gas also supplies subsidized natural gas for industrial processes. Industries such as ceramic manufacturing (Twyford, Wangkang, and Sentuo), fertilizer production, and petrochemicals utilize natural gas from Ghana Gas to enhance their operations, increase efficiency, and reduce production costs. Ghana Gas is currently in the process of providing gas as a feedstock for fertilizer production and also as fuel for mining operations.
              </p>
              <p class="text-justify">
              Liquefied Petroleum Gas (LPG): LPG is transported through pipelines to a Bulk Distribution Company (BDC) 2.5km from GPP for onward sale to the consuming public. In addition to processing and distributing natural gas, Ghana Gas has advanced plans to operate an LPG bottling plant and storage facilities to ensure the availability of LPG for households and businesses in line with the government cylinder recirculation policy.
              </p>
              <p class="text-justify">
              Expansion and Development: Ghana Gas continues to explore opportunities for expansion and development of its infrastructure to meet the increasing demand for natural gas. This includes plans for constructing additional gas processing plants (GPP Train II), expanding pipeline networks, and exploring new sources of natural gas reserves in Ghana's offshore fields.              </p>
            </div>
          </div>
        </div>
        <div class="w-full sm:w-1/2">
          <img
            src={OperationsTwo}
            alt="Image"
            class="w-full xl:h-[1100px] object-cover"
          />
        </div>
      </div>

    <div className="relative hover:cursor-pointer" onClick={RTI}>
      <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black bg-opacity-50 flex">
        <h1 className="text-white font-fjalla text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
          Right to Information
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
        src={Information}
        alt="Ghana Gas GPP"
      />
    </div>

    <div className="relative hover:cursor-pointer" onClick={Stra}>
      <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
        Strategy
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
        src={Strategic}
        alt="Ghana Gas GPP"
      />
    </div>

      <Footer />
    </>
  );
}

export default Operations;
