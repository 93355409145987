import React from 'react'
import HeaderImage from '../assets/executive.png'
import Navigation from '../navbar'
import Asmah from '../assets/leadership/alma.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const Management = () => {
        navigate("/robertasmah")
    }

    const ExecuManagement = () => {
        navigate("/leadership")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Executive Management
            </h1>

            <div class="grid md:grid-cols-3 phone:grid-cols-2 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Alma Leigh Mensah</p>
                        <p class="text-black font-fira md:text-[20px] phone:text-[15px] md:mx-20 phone:mx-10">General Manager, Human Resource and Internal Communications</p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            Alma-Leigh Mensah draws on over 20 years of experience in senior management roles in Human
                            Resource Management and Administration and holds an MBA from the University of Liverpool and a BA
                            in French from the Université du Benin.</p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            Alma has held senior HR and Administration positions in private and public sector organizations, leading
                            multicultural teams to drive value. Prior to joining Ghana Gas, Alma was head of Admin and Finance at
                            the ECOWAS Centre for Renewable Energy and Energy Efficiency, a portfolio that was also responsible
                            for Human Resources. She was previously the head of HR at Taylor Woodrow, a role that managed and
                            developed a workforce of over 3,000 employees, leading to multiple awards and achievements for the
                            company.                            </p>

                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            Alma brings a broad range of skills in Strategic Human Resource Management from a global perspective
                            and has a solid understanding of people strategies. She is a member of the Institute of Human Resource
                            Management Practitioners (Ghana), Society for Human Resource Practitioners (USA) and the Chartered
                            Institute of Personnel Development (UK).</p>
                        <br />

                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            In addition to developing people, Alma is an advocate for women’s rights in the workplace and is
                            passionate about harnessing technology towards building a resilient workforce
                        </p>

                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                            Alma speaks English, French and Portuguese and her native Ga.                            </p>
                    </div>
                </div>
            </div>
            <br />

            <Footer />
        </>
    )

}

export default Leadership;