import React from 'react';
import Navigation from '../navbar'
import Footer from '../footer';
import BoardImage from '../assets/Board.png'
import CEO from '../assets/leadership/ceo.png'
import Ken from '../assets/board/Ken.png'
import Awulae from '../assets/board/Awulae.png'
import Nayina from '../assets/board/Sumani.png'
import Delphine from '../assets/board/Delphine.png'
import Abraham from '../assets/board/Osei.png'
import Owusu from '../assets/board/Owusu.png'
import Nana from '../assets/board/Asiaa.png'
import John from '../assets/board/John.png'



function Board (){
  return(
    <>
    <Navigation/>
        <div className="relative">
      <div className="absolute inset-0 bg-black bg-opacity-50 flex">
        <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
            Board of Directors
        </h1>
      </div>
      <img
        className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
        src={BoardImage}
        alt="Ghana Gas GPP"
      />
    </div>
   
    <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
    Board of Directors
    </h1>

    <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-1 xl:px-4 xl:mx-auto xl:mt-[5rem] xl:mb-[10rem]'>
  <div className='flex flex-col sm:flex-row w-full'>
    <div className='pl-[10rem]'>
      <img src={Ken} alt="CEO" className='xl:scale-100' />
    </div>
    <div className='flex flex-col sm:pl-5 sm:pt-2 xl:pr-[10rem] ' >
      <p className='text-black font-fjalla text-2xl sm:text-4xl'>Kennedy Ohene Agyapong </p>
      <p className='text-black font-fira text-xl sm:text-2xl'>Board Chairman</p>
      <p className='text-black font-fira text-lg sm:text-xl text-justify leading-snug'>
      Mr. Kennedy Ohene Agyapong, MP is a Ghanaian politician who represents Assin Central in Parliament for the New Patriotic Party.
He is currently the Chairman of the Parliament’s Defense and Interior Committee and the Board Chairman of Ghana National Gas Company Limited.     
 </p>
    </div>
  </div>
</div>

<div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center'>
          <img src={Awulae} className='scale-75' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Awulae Amihere Kpanyinli III</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>
        <div className='flex flex-col items-center text-center'>
          <img src={CEO} className='scale-75' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Dr. Ben  K.D. Asante</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>
      </div>      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center'>
          <img src={Nayina} className='scale-75' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Mr. Stephen Sumani Nayina</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>
        <div className='flex flex-col items-center text-center'>
          <img src={John} className='scale-75' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Mr. John Darko</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>
      </div>      
      
      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center'>
          <img src={Delphine} className='scale-75' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Mad. Delphine Dogbegah</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>
        <div className='flex flex-col items-center text-center'>
          <img src={Nana} className='scale-75' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Nana Adwoa Asiaa Benneh Beyeeman I</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>
      </div>      
      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center'>
          <img src={Owusu} className='scale-75' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Nana Owusu Ansah Ababio</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>
        <div className='flex flex-col items-center text-center'>
          <img src={Abraham} className='scale-75' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Mr. Abraham Osei Aidooh</span>
          <span className='font-fira text-[17px]'>Member</span>
        </div>
      </div> 

         
      <Footer />  
     
    </>
  )
}

export default Board;