import React from "react";
import Logo from "./assets/company-logo.png";

function SocialMedia() {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="col-span-1 md:col-span-1">
          <div className="flex flex-row space-x-10">
            <div className="flex items-center justify-center px-5 bg-white">
              <div className="max-w-lg rounded-3xl p-px bg-gradient-to-b from-yellow-300 to-pink-300">
                <div className="rounded-[calc(1.5rem-1px)] p-10 bg-white">
                  <p className="text-dark">Celebrating Kwame Nkrumah's legacy today. His dedication to African unity and independence still inspires us.</p>

                  <div className="mt-8 flex gap-4 items-center">
                    <img className="h-10 w-14 rounded-full" src={Logo} alt="" />
                    <div>
                      <h3 className="md:text-lg phone:text-md text-dark ">Ghana National Gas Company</h3>
                      <span className="text-sm tracking-wide text-dark">@GNGCOfficial</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 md:col-span-1">
          <iframe
            src="https://www.youtube.com/embed/KdgWG19OggI?si=AxBIJaU13GUbY6T_&amp;controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            className="w-full h-full"
          ></iframe>
        </div>
        <div className="col-span-1 md:col-span-1">
        <iframe src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fpermalink.php%3Fstory_fbid%3Dpfbid0ssfxgdQtkNzj6kweqsBvRoGDoQPLv57q5mf2tQdmK16ufiaUFe4dN4NncR5hazXfl%26id%3D100063778503921&width=500&show_text=false&height=460&appId" className="h-full w-full"></iframe>
        </div>
      </div>
    </>
  );
}

export default SocialMedia;
