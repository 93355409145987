import React from 'react'
import HeaderImage from '../assets/executive.png'
import Navigation from '../navbar'
import Asmah from '../assets/leadership/abraham.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const Management = () => {
        navigate("/robertasmah")
    }

    const ExecuManagement = () => {
        navigate("/management")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Management
            </h1>

            <div class="grid md:grid-cols-3 phone:grid-cols-2 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Dr. Abraham Mensah</p>
                        <p class="text-black font-fira md:text-[20px] phone:text-[15px] md:mx-20 phone:mx-10">Head, Office of the CEO </p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Dr. Abraham Mensah is an enthusiastic, versatile and seasoned executive with over 15 years’ experience in the Oil and Gas Upstream and Midstream space; particularly in pipelines, reliability and subsea structural engineering.    </p>

                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Abraham’s zest for developing Oil and Gas projects as well as the optimization of maintenance planning based on risk management has driven him across many sectors within the Oil and Gas industry including Technip (France), WorleyParsons/ INTECSEA (UK), and Ghana’s premier natural gas service company, the Ghana National Gas Company (Ghana). </p>  <br />

                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Particularly adept in agile forms of asset integrity management, Abraham has held varying technical positions and has demonstrated proficiency in mobilizing and leading multi-national project teams for major endeavors around onshore and offshore gas pipelines and subsea field development, FEED, EPCC, as well as O&M.                         </p>

                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Abraham is a Senior Professional Member of the Ghana Institution of Engineering and a certified Safety Management System Auditor. He holds a PhD degree in Engineering from the University of Aberdeen, UK, a postgraduate masters degree in Pipeline Engineering from the Newcastle University, UK and a bachelors degree in Civil Engineering from the Kwame Nkrumah University of Science and Technology (KNUST), Kumasi, Ghana.  </p>

                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Abraham is currently the Senior Technical Manager of the office of the CEO of the Ghana National Gas Company. Abraham has consistently delivered in areas of infrastructure planning and engineering design, project appraisal, and asset integrity management. He currently serves on the technical committee of the Energy Commission. </p>

                        <br />

                    </div>
                </div>
            </div>
            <br />

            <Footer />
        </>
    )

}

export default Leadership;