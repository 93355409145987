import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Leadership from './assets/leadership.jpg';
import Mentorship from './assets/Mentorship.png';
import Work from './assets/Work at Ghana Gas.png';
import OurPeople from './assets/our people.png';

const ImageStack = () => {
  const [activeImage, setActiveImage] = useState(1);

  const handleImageHover = (imageNumber) => {
    setActiveImage(imageNumber);
  };

  let navigate = useNavigate();

  const People = () => {
    navigate('/ourpeople');
  };
  const Leaders = () => {
    navigate('/leadership');
  };

  return (
    <>
<div className="flex w-full sm:h-[200px] md:h-[500px] small-laptop:h-[520px] macbook-air:h-[590px] macbook:h-[560px] md:h-[600px] hp-desktop:h-[780px] tablet:h-[392px] 4K-screen:h-[1040px] laptop:h-[420px] absolute z-10 overflow-x-hidden overflow-y-hidden">
  {/* Column 1 */}
  <div className="w-1/4 p-4 bg-black bg-opacity-10 hover:cursor-pointer transition ease-in duration-500 hover:bg-black hover:bg-opacity-50" onMouseOver={() => handleImageHover(4)} onClick={People}>
  <h2 className={`font-fjalla text-4xl small-laptop:mt-[20rem] hp-desktop:mt-[35rem] macbook-air:mt-[22rem] tablet:mt-[12rem] tablet:text-[27px] 4K-screen:text-[50px] 4K-screen:mt-[45rem] ${activeImage === 2 ? 'text-black' : 'text-white'} ${activeImage === 3 ? 'text-black' : 'text-white'} ${activeImage === 1 ? 'text-black' : 'text-white'}`}>Our People</h2>
    <p className={`text-white font-fira transition ease-in duration-1000 xl:pt-6 ${activeImage === 4 ? 'block' : 'hidden'}`}>At Ghana Gas, our people are our strength. The dedicated men and women who comprise our workforce are some of the finest employees within corporate Ghana.</p>
  </div>

  {/* Column 2 */}
  <div className="w-1/4 border-l-2 border-white p-4 hover:cursor-pointer bg-black bg-opacity-10 hover:bg-black hover:bg-opacity-50" onMouseOver={() => handleImageHover(3)}>
    <h2 className="text-xl font-semibold"></h2>
    <h2 className={`font-fjalla text-4xl small-laptop:mt-[20rem] hp-desktop:mt-[35rem] macbook-air:mt-[22rem] tablet:mt-[12rem] tablet:text-[27px] 4K-screen:text-[50px] 4K-screen:mt-[45rem] ${activeImage === 1 ? 'text-black' : 'text-white'} ${activeImage === 2 ? 'text-black' : 'text-white'} ${activeImage === 4 ? 'text-black' : 'text-white'}`}>Work at Ghana Gas</h2>
    <p className={`text-white font-fira transition ease-in duration-1000 xl:pt-6 ${activeImage === 3 ? 'block' : 'hidden'}`}>The company encourages teamwork, loyalty, respect, integrity, accountability, excellence and professionalism and is concerned about the environment.</p>
  </div>

  {/* Column 3 */}
  <div className="w-1/4 p-4 border-l-2 bg-black hover:cursor-pointer bg-opacity-10 hover:bg-black hover:bg-opacity-50" onMouseOver={() => handleImageHover(1)} onClick={Leaders}>
  <h2 className={`font-fjalla text-4xl small-laptop:mt-[20rem] hp-desktop:mt-[35rem] macbook-air:mt-[22rem] tablet:mt-[12rem] tablet:text-[27px] 4K-screen:text-[50px] 4K-screen:mt-[45rem] ${activeImage === 2 ? 'text-black' : 'text-white'} ${activeImage === 3 ? 'text-black' : 'text-white'} ${activeImage === 4 ? 'text-black' : 'text-white'}`}>Leadership</h2>
    <p className={`text-white font-fira transition ease-in duration-1000 xl:pt-6 ${activeImage === 1 ? 'block' : 'hidden'}`}>At Ghana Gas, our success is driven by a dynamic and visionary leadership team committed to innovation, excellence, and integrity. </p>
  </div>

  {/* Column 4 */}
  <div className="w-1/4 p-4 border-l-2 bg-black hover:cursor-pointer bg-opacity-10 hover:bg-black hover:bg-opacity-50" onMouseOver={() => handleImageHover(2)}>
  <h2 className={`font-fjalla text-4xl small-laptop:mt-[20rem] hp-desktop:mt-[35rem] macbook-air:mt-[22rem] tablet:mt-[12rem] tablet:text-[27px] 4K-screen:text-[50px] 4K-screen:mt-[45rem] ${activeImage === 1 ? 'text-black' : 'text-white'} ${activeImage === 3 ? 'text-black' : 'text-white'} ${activeImage === 4 ? 'text-black' : 'text-white'}`}>Our Culture</h2>
    <p className={`text-white font-fira transition ease-in duration-1000 xl:pt-6 ${activeImage === 2 ? 'block' : 'hidden'} `}>The company has well-defined training programs for its officers that enhance their skills and capabilities, encouraging staff to remain committed to the values and vision of GNGC</p>
  </div>
</div>


      <div className="relative">
        <img
          src={OurPeople}
          alt="Image 4"
          onMouseOver={() => handleImageHover(4)}
          className={`absolute w-full inset-0 transition-opacity duration-700 ${activeImage === 4 ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        />

        <img
          src={Work}
          alt="Image 3"
          onMouseOver={() => handleImageHover(3)}
          className={`absolute w-full inset-0 transition-opacity duration-700 ${activeImage === 3 ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        />

        <img
          src={Mentorship}
          alt="Image 2"
          onMouseOver={() => handleImageHover(2)}
          className={`absolute w-full inset-0 transition-opacity duration-700 ${activeImage === 2 ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        />

        <img
          src={Leadership}
          alt="Image 1"
          onMouseOver={() => handleImageHover(1)}
          className={`absolute w-full inset-0 transition-opacity duration-700 ${activeImage === 1 ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
        />
      </div>
    </>
  );
};

export default ImageStack;
