import React from 'react';
import Logo from '../assets/company-logo.png';

function App() {

  return (
    <div className="flex flex-col items-center justify-center my-20">
      <div className="flex flex-col items-center justify-start h-full">
        <div className="flex items-center justify-center mt-16 mb-4">
          <img src={Logo} className="w-48 h-28" alt="Company Logo" />
        </div>
        <h1 className="font-fjalla text-gasblue text-xl xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[20px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[32px] xl:mx-[270px]  xl:leading-snug">
          This Page is Under Development
        </h1>
        <a href="https://www.ghanagas.com.gh" rel="noopener noreferrer">
          <button className="transition ease-in-out rounded-[5px] border-[1px] border-gasblue hover:bg-white duration-700 hover:text-black bg-gasblue px-4 text-white font-fira text-[28px] xl:mx-[270px] sm:p-5">
            Click here to go to current website
          </button>
        </a>
      </div>
    </div>
  );
}

export default App;