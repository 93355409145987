import React from 'react';
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';


function NextPage() {
  let navigate = useNavigate();
  
  const AboutUs = () => {
    navigate("/profile")
  }


  return (
    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full md:h-[500px] phone:h-[250px] bg-[#F6F6FA]">
      <div className="col-span-1 md:col-span-2 md:border-l-2 md:border-l-gray-400 text-center phone:hidden md:block">
      </div>
      <div className="col-span-1 md:col-span-2 border-l-2 border-l-gray-400 text-center">
        <div className="md:pt-[227px] phone:pt-[70px]">
          <div onClick={AboutUs} className="flex flex-row mx-auto md:hidden lg:block md:ml-[32rem] macbook-air:ml-[25rem] hp-desktop:ml-[32rem] tablet:ml-[17rem] phone:ml-[16rem] 4K-screen:ml-[48rem]">
            <h2 className="font-fira md:hidden hover:cursor-pointer">Next</h2>
            <FaArrowRight />
          </div>
          <h2 onClick={AboutUs} className="font-fjalla text-[55px] hover:cursor-pointer">About Us</h2>
        </div>
      </div>
    </div>
  );
}

export default NextPage;
