import React from 'react';
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaSquareFacebook } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

function Footer() {
  let navigate = useNavigate();

  const Mandate = () => {
    navigate("/mandate");
  };
  const AboutUs = () => {
    navigate("/aboutus");
  };

  const History = () => {
    navigate("/history");
  };
  const Careers = () => {
    navigate("/careers");
  };
  const Facilities = () => {
    navigate("/facilities");
  };
  const Community = () => {
    navigate("/community");
  };
  const Media = () => {
    navigate("/media");
  };
  const Operations = () => {
    navigate("/operations");
  };
  const Infrastructure = () => {
    navigate("/infrastructure");
  };
  const Leadership = () => {
    navigate("/leadership");
  };

  return (
    <footer className="bg-gasblue text-white py-6 md:px-20 phone:px-[100px]">
      <div className="flex flex-col md:flex-row text-[22px] md:text-[18px] justify-between w-full hover:cursor-pointer font-fira pt-[100px]">

        {/* Column 1 */}
        <div className="w-full md:w-1/4 sm:w-1/2 mb-4 md:mb-0 hover:cursor-pointer phone:w-2/4">
          <ul>
            <li onClick={AboutUs}>About Us</li>
            <li onClick={Mandate}>Mandate</li>
            <li onClick={History}>History</li>
            <li>Certification</li>
            <li>Sitemap</li>
          </ul>
        </div>

        {/* Column 2 */}
        <div className="w-full md:w-1/4 sm:w-1/2 mb-4 md:mb-0 hover:cursor-pointer">
          <ul>
            <li>Contact Us</li>
            <li onClick={Careers}>Careers</li>
            <li onClick={Facilities}>Facilities</li>
            <li onClick={Community}>Community Projects</li>
            <li>Events</li>
          </ul>
        </div>

        {/* Column 3 */}
        <div className="w-full md:w-1/4 sm:w-1/2 mb-4 md:mb-0 hover:cursor-pointer">
          <ul>
            <li onClick={Media}>Media</li>
            <li onClick={Operations}>Operations</li>
            <li onClick={Infrastructure}>Infrastructure</li>
            <li onClick={Leadership}>Leadership</li>
            <li>Company Scholarship</li>
          </ul>
        </div>

        {/* Column 4 - Social Icons */}
        <div className="w-full md:w-1/4 sm:w-1/2 mb-4 md:mb-0 mt-5">
          <div className="flex flex-col items-center"> {/* Center content horizontally and vertically */}
            <div className='flex flex-row'>
              <FaSquareXTwitter className='scale-[2.5] hover:cursor-pointer'/>
              <FaSquareInstagram className='scale-[2.5] hover:cursor-pointer ml-7'/>
            </div>
            <div className='flex flex-row mt-7'>
              <FaSquareFacebook className='scale-[2.5] hover:cursor-pointer' />
              <FaYoutubeSquare className='scale-[2.5] hover:cursor-pointer ml-7'/>
            </div>
          </div>
        </div>
      </div>
      <div>
<h1 className='font-fjalla pt-3'>© 2024 Ghana National Gas Limited Company</h1>
      </div>
    </footer>
  );
}

export default Footer;
