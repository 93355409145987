import React from 'react';
import HeaderImage from '../assets/Mentorship.png';
import Social from '../assets/Social Investment.png';
import Right from '../assets/Work at Ghana Gas.png';
import Footer from '../footer'
import Navigation from '../navbar'
import { useNavigate } from 'react-router-dom';

function Profile() {
  let navigate = useNavigate();

  const SocialI = () => {
    navigate("/socialinvestment")
  }

  const Information = () => {
    navigate("/righttoinformation")
  }

  return (
    <>
      <Navigation />
      <div className="relative">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
          src={HeaderImage}
          alt="Ghana Gas GPP"
        />
      </div>
      <div>
        <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
          Mentorship Application Form
        </h1>
        <h1 className="font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:text-[28px] xl:mx-[270px]">
          To be eligible for selection as a GNGC mentorship beneficiary, mentees should complete this form.    
        </h1>
        <h1 className="font-fjalla text-gasblue text-2xl phone:p-4 phone:text-justify xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
          Eligibility
        </h1>
        <h1 className="font-fira text-dark text-xl phone:p-4 phone:text-justify xs:text-[15px] xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:text-[28px] xl:mx-[270px]">
          Applicant should be self-motivated and eager to develop him or herself in the chosen discipline of interest.<br />
          He/She should be a good listener and willing to learn under the supervision of a mentor.<br />
          The mentee must be purpose driven and self-motivated.<br />
          He/She should be receptive to feedback.<br />
        </h1>


        <div className="relative hover:cursor-pointer" onClick={SocialI}>
          <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black bg-opacity-50 flex">
            <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
              Social Investment
            </h1>
          </div>
          <img
            className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
            src={Social}
            alt="Ghana Gas GPP"
          />
        </div>

        <div className="relative hover:cursor-pointer" onClick={Information}>
          <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black bg-opacity-50 flex">
            <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
              Right to Information
            </h1>
          </div>
          <img
            className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
            src={Right}
            alt="Ghana Gas GPP"
          />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Profile;
