import React, { useState } from 'react';
import { useNavigate } from "react-router-dom"
import Flame from "./assets/gasflame.png"
import Security from './assets/marine-security.jpg'
import GasProcessing from './assets/The Environment.png'
import Pipelines from './assets/pipelines.jpg'




function MobileView(){
    return(

        <>
<div className="relative">
  <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:ml-10 xl:text-6xl xl:mx-[270px]">
      Health, Safety and Environment
    </h1>
  </div>
  <img
    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full"
    src={Flame}
    alt="Ghana Gas GPP"
  />
</div>

<div className="relative">
  <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:ml-10 xl:text-6xl xl:mx-[270px]">
Marine and Security   </h1>
  </div>
  <img
    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full"
    src={Security}
    alt="Ghana Gas GPP"
  />
</div>

<div className="relative">
  <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:ml-10 xl:text-6xl xl:mx-[270px]">
     Gas Plant Operations
    </h1>
  </div>
  <img
    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full"
    src={GasProcessing}
    alt="Ghana Gas GPP"
  />
</div>

<div className="relative">
  <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:ml-10 xl:text-6xl xl:mx-[270px]">
Pipelines and Stations    </h1>
  </div>
  <img
    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full"
    src={Pipelines}
    alt="Ghana Gas GPP"
  />
</div>
      
              </>


    )
}

export default MobileView;