import React from 'react'
import HeaderImage from '../assets/executive.png'
import BoardDirector from '../assets/Board.png';
import OurPeople from '../assets/leadership.jpg';
import OurPeople1 from '../assets/our people.png';
import Navigation from '../navbar'
import Albert from '../assets/leadership/albert.png'
import Bempah from '../assets/leadership/owusu-bempah.png'
import Augustina from '../assets/leadership/augustina.png'
import Donkor from '../assets/leadership/donkor.png'
import Abraham from '../assets/leadership/abraham.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
  let navigate = useNavigate();

  const OB = () => {
    navigate("/owusubempah")
  }
  const AB = () => {
    navigate("/abrahammensah")
  }
  const Nanevie = () => {
    navigate("/albert")
  }
  const AO = () => {
    navigate("/augustina")
  }
  const Board = () => {
    navigate("/board")
  }

  const People = () => {
    navigate("/leadership")
  }

  
  const People1 = () => {
    navigate("/ourpeople")
  }
  return (

    <>

      <Navigation />

      <div className="relative">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
          src={HeaderImage}
          alt="Ghana Gas GPP"
        />
      </div>

      <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
      Management
      </h1>

      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={OB}>
          <img src={Bempah} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Ernest Owusu-Bempah Bonsu</span>
          <span className='font-fira text-[17px]'>Head, Corporate Communications</span>
        </div>
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={AB}>
          <img src={Abraham} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Dr. Abraham Mensah</span>
          <span className='font-fira text-[17px]'>Head, Office of the CEO</span>
        </div>
      </div>

      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={Nanevie}>
          <img src={Albert} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Albert Nanevie</span>
          <span className='font-fira text-[17px]'>Head, Internal Audit</span>
        </div>
        <div className='flex flex-col items-center text-center hover:cursor-pointer' onClick={AO}>
          <img src={Augustina} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Augustina Asare Osei</span>
          <span className='font-fira text-[17px]'>Head, Government Relations</span>
        </div>
      </div>

      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">
        <div className='flex flex-col items-center text-center hover:cursor-pointer'>
          <img src={Donkor} className='md:scale-75 phone:scale-50' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Stephen Donkor</span>
          <span className='font-fira text-[17px]'>Head, Community Relations & CSR</span>
        </div>
      </div>

{/* 
      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">

        <div className='flex flex-col items-center text-center'>
          <img src={Bempah} className='scale-75' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Ernest Owusu-Bempah Bonsu</span>
          <span className='font-fira text-[17px]'>Head, Corporate Communications</span>
        </div>
        <div className='flex flex-col items-center text-center'>
          <img src={Augustina} className='scale-75' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Augustina Osei Asare</span>
          <span className='font-fira text-[17px]'>Senior Manager, Government Relations</span>
        </div>
      </div>

      <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 xl:px-[12rem] xl:pt-[8rem]">

        <div className='flex flex-col items-center text-center'>
          <img src={Albert} className='scale-75' alt="Stephen Borteye Jomo" />
          <span className='font-fjalla text-[22px]'>Albert Nanevie</span>
          <span className='font-fira text-[17px]'>Head, Internal Audit</span>
        </div>
        <div className='flex flex-col items-center text-center'>
          <img className='scale-75' alt="" />
          <span></span>
          <span></span>
        </div>
      </div>
*/}
<br/>
<br/>
      <div className="relative hover:cursor-pointer" onClick={People}>
        <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
            Executive Management
          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
          src={OurPeople}
          alt="Ghana Gas GPP"
        />
      </div>

      <div className="relative hover:cursor-pointer" onClick={People1}>
        <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
            Our People
          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
          src={OurPeople1}
          alt="Ghana Gas GPP"
        />
      </div>


      <Footer />
    </>
  )

}

export default Leadership;