import React from 'react';
import Navigation from '../navbar';
import Footer from '../footer';
import GC19 from '../assets/gc2019.jpg'
import Birth from '../assets/About us.png'
import Anniversary from '../assets/history/10years.jpg'
import AnniversaryFull from '../assets/history/10years-1.jpg'
import Building from '../assets/history/building.jpg'
import Complex from '../assets/history/complex.jpg'
import GPP2 from '../assets/history/gpp2.jpg'
import GasHouse from '../assets/history/gashouse.jpg'
import GasHouseFull from '../assets/history/gashouse-1.jpg'
import Indigenous from '../assets/history/indigenous.jpg'
import ISO from '../assets/history/iso.jpg'
import ISOFull from '../assets/history/iso-1.jpg'
import Karpower from '../assets/history/karpower.jpg'
import TDS from '../assets/history/tds.jpg'
import Shutdown from '../assets/history/shutdown.jpg'
import FirstGas from '../assets/history/firstgas.jpg'
import Pipeline from '../assets/history/pipeline.jpg'
import Station from '../assets/Profile.jpg'
import Construction from '../assets/history/construction.jpg'
import Debuthanizer from '../assets/history/Debuthanizer.jpg'
import Gas from '../assets/history/firstgas-1.jpg'
import IndigenousOne from '../assets/history/indigenous-staff.jpg'
import Maintenance from '../assets/history/maintenance.jpg'
import EPLine from '../assets/history/epline.jpg'
import Operations from '../assets/operations-1.png'
import Commercial from '../assets/operations-2.png'
import Sanzule from '../assets/sanzule.jpg'


function Events() {
    return (
        <>
            <Navigation />
            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
                      History
                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:mb-[5rem]"
                    src={GasHouse}
                    alt="Ghana Gas GPP"
                />
            </div>

            <div className="relative">
                <img src={Birth} alt="Your Image" class="xl:h-[434px] xl:w-full object-cover mb-5 mt-5" />
                <h1 className='grid md:grid-cols-2 text-justify  phone:grid-cols-1 font-fira md:text-[26px] phone:text-[18px] md:px-[4rem] phone:px-7 phone:py-7 md:py-[4rem]'>
                    Ghana, the land of Gold was yet again blessed with another rich resource- OIL in 2007. The oil production however started in 2010. With about 368 million tons of oil found 60km offshore, between the Deepwater Tano and West Cape Three Points, it became necessary that a Gas plant be established to make good use of the associated gas.
                </h1>
                <img src={Building} alt="Your Image" class="xl:h-[434px] xl:w-full object-cover" />
                <h1 className='grid md:grid-cols-2 text-justify phone:grid-cols-1 font-fira md:text-[26px] phone:text-[18px] md:px-[4rem] phone:px-7 phone:py-7 md:py-[4rem]'>
                    In view of this, ‘National Gas Development Taskforce’ was commissioned in February 2011 by the government to review and make appropriate recommendations for the speedy recommendation on the most efficient way to gather our gas reserves and its utilization to benefit the country.
                    In April 2011, the Taskforce submitted its report recommending Natural Gas from the Jubilee Fields is gathered, processed and Lean Gas processed used as the main source of fuel for power generation as well as LPG and Condensate to be on the Ghanaian market.
                    Then, on 10th June, 2011, the Natural Gas Project Implementation Directorate was formed to work closely with the Taskforce. </h1>
                <img src={Pipeline} alt="Your Image" class="xl:h-[434px] xl:w-full object-cover" />
                <h1 className='grid md:grid-cols-2 phone:grid-cols-1 font-fira md:text-[26px] phone:text-[18px] md:px-[4rem] phone:px-7 phone:py-7 md:py-[4rem]'>
                    Acting upon the above, the government then sanctioned the establishment of an indigenous company to
                    undertake the project. As a result, the Ghana National Gas Company (GNGC) was formed, hence giving true
                    meaning to government’s emphasis on ‘local content’ in the oil and gas industry.

                </h1>
                <img src={FirstGas} alt="Your Image" class="xl:h-[434px] xl:w-full object-cover" />
                <h1 className='grid md:grid-cols-2 text-justify phone:grid-cols-1 font-fira md:text-[26px] phone:text-[18px] md:px-[4rem] phone:px-7 phone:py-7 md:py-[4rem]'>
                    In November 2011, Ghana Gas signed a Project Implementation Agreement (PIA) with the Contractor to enable the early implementation of the Project.  Subsequently, a Memorandum of Understanding(MOU) was signed in Beijing on 24th March 2012.  Finally, on 12th July 2012, an Engineering, Procurement, Construction and Commissioning (EPCC) Agreement was signed with the contractor, Sinopec International Petroleum Services Corporation (Sinopec Services)
                </h1>
                <img src={Indigenous} alt="Your Image" class="xl:h-[434px] xl:w-full object-cover" />
                <h1 className='grid md:grid-cols-2 text-justify phone:grid-cols-1 font-fira md:text-[26px] phone:text-[18px] md:px-[4rem] phone:px-7 phone:py-7 md:py-[4rem]'>
                    After two years of Operations and Maintenance agreement with Sinopec, knowledge transfer was done between Ghanaian engineers and their respective Sinopec counterpart, and our engineers were trained to attain the required skills and expertise to fully take charge of the operations and maintenance of the gas plant and its associated pipelines.                </h1>

                <img src={Shutdown} alt="Your Image" class="xl:h-[434px] xl:w-full object-cover" />
                <h1 className='grid md:grid-cols-2 text-justify phone:grid-cols-1 font-fira md:text-[26px] phone:text-[18px] md:px-[4rem] phone:px-7 phone:py-7 md:py-[4rem]'>
                    ENI Compressor Stations at Sanzule which brings Lean Gas from Sankofa Fields (Kufuor/ENI FPSO) was successfully interconnected into Ghana Gas’ onshore pipeline network from Sanzule. This has enabled the Sankofa gas to be transported through the Ghana Gas onshore pipeline to the various customers across the country, thereby increasing its throughput from an average of 130mmscfd gas flow to about 330mmscfd.                </h1>
                <img src={TDS} alt="Your Image" class="xl:h-[434px] xl:w-full object-cover" />
                <h1 className=' text-justify grid md:grid-cols-2 phone:grid-cols-1 font-fira md:text-[26px] phone:text-[18px] md:px-[4rem] phone:px-7 phone:py-7 md:py-[4rem]'>
                    Ghana Gas with its partner successfully completed the expansion of the Takoradi Distribution Station increasing the capacity of the station from 130mmscfd to 405mmscfd, hence capable of handling increased flow from the TEN, Jubilee and Sankofa fields.                </h1>
                <img src={GasHouse} alt="Your Image" class="xl:h-[434px] xl:w-full object-cover" />
                <h1 className='text-justify grid md:grid-cols-2 phone:grid-cols-1 font-fira md:text-[26px] phone:text-[18px] md:px-[4rem] phone:px-7 phone:py-7 md:py-[4rem]'>
                    In May 2018, Ghana Gas acquired a new Corporate Head office (Gas House) which is located at Airport West Residential Area, an enclave that houses other giant companies in the oil and gas industry such as KOSMOS Energy, Tullow Oil, National Petroleum Authority, Petroleum Commission among others.                 </h1>
                <img src={Complex} alt="Your Image" class="xl:h-[434px] xl:w-full object-cover" />
                <h1 className='text-justify grid md:grid-cols-2 phone:grid-cols-1 font-fira md:text-[26px] phone:text-[18px] md:px-[4rem] phone:px-7 phone:py-7 md:py-[4rem]'>
                    The Gas Complex in A. B. Bokazo, situated in the Nzema area of the Western Region of Ghana, offers suitable office facilities and a convenient location for the dedicated personnel of Ghana Gas who work at the operational sites. The selection of this site was based on its central and equidistant proximity to both the Gas Processing Plant (GPP) in Atuabo and the Esiama Distribution Station(EDS).
                </h1>

                <img src={Karpower} alt="Your Image" class="xl:h-[434px] xl:w-full object-cover" />
                <h1 className='text-justify grid md:grid-cols-2 phone:grid-cols-1 font-fira md:text-[26px] phone:text-[18px] md:px-[4rem] phone:px-7 phone:py-7 md:py-[4rem]'>
                    GNGC undertook the construction of onshore and offshore pipelines as well as metering and regulatory station for the switching of the Karpowership fuel to indigenous natural gas from Ghana Gas operations. The project serves a significant step towards meeting electricity demands in Ghana’s power industry.                </h1>
                <img src={Anniversary} alt="Your Image" class="xl:h-[434px] xl:w-full object-cover" />
                <h1 className='text-justify grid md:grid-cols-2 phone:grid-cols-1 font-fira md:text-[26px] phone:text-[18px] md:px-[4rem] phone:px-7 phone:py-7 md:py-[4rem]'>
                    Ghana National Gas Company marked her 10th anniversary in grand style, featuring a number of activities to honour the decade. The well-organized ceremony had fun-packed activities such as community engagement and games, health walk, health screening, Cultural night and a thanksgiving service among others.                </h1>
                <img src={ISO} alt="Your Image" class="xl:h-[434px] xl:w-full object-cover" />
                <h1 className='text-justify grid md:grid-cols-2 phone:grid-cols-1 font-fira md:text-[26px] phone:text-[18px] md:px-[4rem] phone:px-7 phone:py-7 md:py-[4rem]'>
                    GNGC has been ISO 45001:2018 certified, this certification means that the company’s Occupational Health and Safety (OH&S) management system meet international standards.                 </h1>
                <img src={GPP2} alt="Your Image" class="xl:h-[434px] xl:w-full object-cover" />
                <h1 className='text-justify grid md:grid-cols-2 phone:grid-cols-1 font-fira md:text-[26px] phone:text-[18px] md:px-[4rem] phone:px-7 phone:py-7 md:py-[4rem]'>
                    GNGC signed a project agreement for the construction of a second Gas Processing Plant. The Gas Processing Plant will have a capacity of 150 million cubic feet per day (MMscfd) and can be expandable to 300 MMscfd to process incremental raw gas volumes from the Jubilee and TEN fields.                </h1>
                
                
                <span className="md:absolute phone:relative top-0 phone:bottom-0 right-0 bg-black md:h-full phone:h-2/6 4K-screen:h-full md:w-[546px] md:mr-[2rem] 4K-screen:mr-[2rem] phone:w-[300px] phone:mr-0  bg-opacity-75 md:space-y-[7rem] phone:space-y-[6rem]">

                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white text-white p-4'>Timeline</h1>

                    <div class="grid grid-cols-4 ">
                        <div class="col-span-2 p-4"><img src={Station} /></div>
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5 ">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white pt-3'>Establishment of Ghana Gas</h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white pr-3'>February - July 2011</h1>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-4 ">
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5 p-3">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white'>Atuabo GPP Project Implementation Agreement signing</h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white'>November 2011</h1>

                                </div>
                            </div>
                        </div>
                        <div class="col-span-2 p-4"><img src={Building} /></div>
                    </div>

                    <div class="grid grid-cols-4 ">
                        <div class="col-span-2 p-4"><img src={Construction} /></div>
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white pt-3'>Construction of GPP, Pipelines and Stations</h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white pr-3'>May 2012 – August 2014</h1>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-4 ">
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5 p-3">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white '>Mounting of Debutanizer and De-Ethanizer </h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white '>17th – 18th June 2013</h1>

                                </div>
                            </div>
                        </div>
                        <div class="col-span-2 p-4"><img src={Debuthanizer} /></div>
                    </div><div class="grid grid-cols-4 ">
                        <div class="col-span-2 p-4"><img src={Operations} /></div>
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white pt-3'>Mechanical Completion</h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white pr-3'>31st August 2014</h1>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-4 ">
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5 p-3">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white '>First Gas at GPP</h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white '>10th November 2014</h1>

                                </div>
                            </div>
                        </div>
                        <div class="col-span-2 p-4"><img src={Gas} /></div>
                    </div><div class="grid grid-cols-4 ">
                        <div class="col-span-2 p-4"><img src={Commercial} /></div>
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white pt-3'>Full Commercial Operation</h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white pr-3'>April 2015</h1>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-4 ">
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5 p-3">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white '>First Successful Maintenance Shutdown</h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white '>April 2015</h1>

                                </div>
                            </div>
                        </div>
                        <div class="col-span-2 p-4"><img src={Shutdown} /></div>
                    </div>
                    
                    

                    <div class="grid grid-cols-4 ">
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5 p-3">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white '>LPG Loading Gantry Mechanical Completion </h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white '>27th December 2016 </h1>

                                </div>
                            </div>
                        </div>
                        <div class="col-span-2 p-4"><img src={Operations} /></div>
                    </div><div class="grid grid-cols-4 ">
                        <div class="col-span-2 p-4"><img src={IndigenousOne} /></div>
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white pt-3'>GPP fully operated by indigenous Ghanaian Staff of Ghana Gas </h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white pr-3'>March 2017</h1>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-4 ">
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5 p-3">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white '>Completion of Commission of Esiama Prestea (EP) Lateral Pipeline </h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white '>November 2017</h1>

                                </div>
                            </div>
                        </div>
                        <div class="col-span-2 p-4"><img src={EPLine} /></div>
                    </div><div class="grid grid-cols-4 ">
                        <div class="col-span-2 p-4"><img src={Maintenance} /></div>
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white pt-3'>First Ghana Gas manned maintenance shutdown </h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white pr-3'>February 2018</h1>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-4 ">
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5 p-3">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white '>ENI Tie-in at Sanzule</h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white '>10th February 2018</h1>

                                </div>
                            </div>
                        </div>
                        <div class="col-span-2 p-4"><img src={Sanzule} /></div>
                    </div><div class="grid grid-cols-4 ">
                        <div class="col-span-2 p-4"><img src={TDS} /></div>
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white pt-3'>TRMS Expansion works and Pipeline Interconnection to WAGPCO</h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white pr-3'>February – November 2018</h1>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-4 ">
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5 p-3">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white '>Acquiring the Gas House </h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white '>May 2018</h1>

                                </div>
                            </div>
                        </div>
                        <div class="col-span-2 p-4"><img src={GasHouseFull} /></div>
                    </div><div class="grid grid-cols-4 ">
                        <div class="col-span-2 p-4"><img src={Complex} /></div>
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white pt-3'>Commission of the Gas Complex </h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white pr-3'>23rd July 2019</h1>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-4 ">
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5 p-3">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white '>First Gas to the Karpowership</h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white '>7th December 2019</h1>

                                </div>
                            </div>
                        </div>
                        <div class="col-span-2 p-4"><img src={Karpower} /></div>
                    </div><div class="grid grid-cols-4 ">
                        <div class="col-span-2 p-4"><img src={AnniversaryFull} /></div>
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white pt-3'>GNGC @ 10  </h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white pr-3'>November – December 2021</h1>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="grid grid-cols-4 ">
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5 p-3">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white '>GNGC was ISO 45001:2018 Certified</h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white '>1st April 2022</h1>

                                </div>
                            </div>
                        </div>
                        <div class="col-span-2 p-4"><img src={ISOFull} /></div>
                    </div><div class="grid grid-cols-4 ">
                        <div class="col-span-2 p-4"><img src={GPP2} /></div>
                        <div class="col-span-2">
                            <div class="grid grid-cols-5">
                                <div class="col-span-5">
                                    <h1 className='font-fjalla text-2xl phone:text-black md:text-white pt-3'>GNGC signed a project agreement for the construction of a second Gas Processing Plant (GPP Train 2)</h1>
                                    <h1 className='font-fira text-xl phone:text-black md:text-white pr-3'>15th March 2023</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                </span>
            </div>

            <div className='mt-[5rem]'>           
             <Footer />
            </div>
        </>
    );
}

export default Events;
