import React, { useState } from 'react';
import Aburi from "./assets/AburiGirls.png";
import FaithPresby from "./assets/FaithPresby.png";
import Juansa from './assets/Juansa.jpg'
import Dzodze from './assets/Dzodze.jpg'
import Dawo from './assets/Dawo.jpg'
import Beyin from './assets/Beyin Classroom.jpg'
import Sekondi from './assets/Sekondi Classroom.jpg'
import Marine from './assets/Marine Police School.jpg'
import Patrick from './assets/St Patrick Classroom.jpg'
import HalfAssini from './assets/Half Assini School.jpg'

import {
  TbSquareArrowRightFilled,
  TbSquareArrowLeftFilled,
} from "react-icons/tb";

const images = [
  { id: 1, src: Aburi, title: 'Aburi Girls Assembly Hall Renovation' },
  { id: 2, src: FaithPresby, title: 'Faith Presbytarian Classroom Block' },
  { id: 3, src: Dzodze, title: 'Dzodze Penji SHS Classroom Project'},
  { id: 4, src: Juansa, title: 'Juansa School Project' },
  { id: 5, src: Dawo, title: 'Dawo Teachers Quarters' },
  { id: 6, src: Beyin, title: '6 - Unit Classroom Block with Ancillary facilities at Beyin ' },
  { id: 7, src: Sekondi, title: '6 - Unit Classroom Block with Concrete Roofed with Ancillary Facilities at Sekondi ' },
  { id: 8, src: Marine, title: 'Rehabilitation of 4 Chamber and Hall Quarters for Marine Police Training School ' },
  { id: 9, src: Patrick, title: 'Rehabilitation of a 6- unit classroom block for St. Patrick Catholic School at Half Assini ' },
  { id: 10, src: HalfAssini, title: 'Renovation of 2 storey Girls Dormitory at Half Assini Senior High School ' },

];

const App = () => {
  const [visibleImages, setVisibleImages] = useState([
    images[0],
    images[1],
    images[2],
    images[3],
  ]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const showNextImages = () => {
    // Calculate the next set of images in a loop
    const nextIndex = (currentIndex + 1) % images.length;
    const nextImages = [
      images[nextIndex],
      images[(nextIndex + 1) % images.length],
      images[(nextIndex + 2) % images.length],
      images[(nextIndex + 3) % images.length],
    ];

    setVisibleImages(nextImages);
    setCurrentIndex(nextIndex);
  };

  const showPreviousImages = () => {
    // Calculate the previous set of images in a loop
    const previousIndex = (currentIndex - 1 + images.length) % images.length;
    const previousImages = [
      images[previousIndex],
      images[(previousIndex + 1) % images.length],
      images[(previousIndex + 2) % images.length],
      images[(previousIndex + 3) % images.length],
    ];

    setVisibleImages(previousImages);
    setCurrentIndex(previousIndex);
  };

  return (
    <div className="flex flex-col mt-[1rem]">
      <div className='flex flex-row justify-between items-center'>
        <h1 className='font-fjalla text-[29px] pl-4'>Educational Projects</h1>
        <div className='flex pr-5'>
          <TbSquareArrowLeftFilled
            onClick={showNextImages}
            className='h-7 w-7 cursor-pointer'
          />
          <TbSquareArrowRightFilled onClick={showPreviousImages} className='h-7 w-7 cursor-pointer' />
        </div>
      </div>
      <div className="flex flex-row w-full space-x-5 px-5">
        {visibleImages.map((image) => (
          <div key={image.id} className="flex flex-col w-1/4">
            <div className="relative">
              <img src={image.src} alt={`Image ${image.id}`} />
              <h1 className='font-fjalla text-[25px] absolute inset-0 text-center text-white bg-black bg-opacity-50 flex justify-center items-center'>
                {image.title}
              </h1>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
