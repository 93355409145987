import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const MobileSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isColumn2Open, setIsColumn2Open] = useState(false);
  const [isColumn3Open, setIsColumn3Open] = useState(false);
  const [isColumn4Open, setIsColumn4Open] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleColumn2 = () => {
    setIsColumn2Open(!isColumn2Open);
  };
  const toggleColumn3 = () => {
    setIsColumn3Open(!isColumn3Open);
  };
  const toggleColumn4 = () => {
    setIsColumn4Open(!isColumn4Open);
  };

  let navigate = useNavigate();

  const AboutUs = () => {
    navigate("/aboutus");
  };

  const Careers = () => {
    navigate("/careers");
  };

  const Profile = () => {
    navigate("/profile");
  };

  const Mission = () => {
    navigate("/mission");
  };

  const Values = () => {
    navigate("/corporatevalues");
  };

  const OurPeople = () => {
    navigate("/ourpeople");
  };

  const Objectives = () => {
    navigate("/corporateobjectives");
  };

  const Environment = () => {
    navigate("/theenvironment");
  };

  const HSEPolicy = () => {
    navigate("/hsepolicy");
  };

  const HRPolicy = () => {
    navigate("/hrpolicy");
  };

  const LocalContent = () => {
    navigate("/localcontent");
  };

  const SocialInvestment = () => {
    navigate("/socialinvestment");
  };

  const RTI = () => {
    navigate("/righttoinformation");
  };

  const Strategy = () => {
    navigate("/strategy");
  };

  const Mandate = () => {
    navigate("/mandate");
  };

  const KeyObjectives = () => {
    navigate("/keyobjectives");
  };

  const BestPractices = () => {
    navigate("/bestpractices");
  };

  const Stakeholders = () => {
    navigate("/stakeholders");
  };

  const Tariff = () => {
    navigate("/tariff");
  };

  const Leadership = () => {
    navigate("/board");
  };

  const Operations = () => {
    navigate("/operations");
  };

  const Mentorship = () => {
    navigate("/mentorship");
  };

  const Media = () => {
    navigate("/media");
  };

  const Community = () => {
    navigate("/community");
  };

  const Management = () => {
    navigate("/management");
  };


  const Home = () => {
    navigate("/");
  };

  const Contact = () => {
    navigate("/contactus")
  }

  const Scholarship = () => {
    navigate("/scholarship")
  }
  return (
    <div className="lg:hidden">
      {!isOpen && (
        <button
          className="fixed top-5 left-0 z-50 p-4 text-white"
          onClick={toggleSidebar}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-9 w-9"
            fill="none"
            viewBox="0 0 24 24"
            stroke="black"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      )}
      <div
        className={`fixed top-0 left-0 z-40 w-64 h-full bg-white  transform transition-transform duration-300 overflow-y-auto ${isOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
      >

        <div className="flex flex-col divide-y divide-gray-300 h-full">
          <div className="p-4">
            <h2 className="font-bold cursor-pointer" onClick={toggleColumn2}>Company</h2> {/* Added onClick event */}
            {isColumn2Open && ( // Conditionally render Column 2
              <nav>
                <ul>
                  <li onClick={Profile}>
                    <a href="#" className="block py-2 hover:bg-gray-100">Profile</a>
                  </li>
                  <li onClick={Mission}>
                    <a href="#" className="block py-2 hover:bg-gray-100">Mission & Vision</a>
                  </li>
                  <li onClick={Values}>
                    <a href="#" className="block py-2 hover:bg-gray-100">Corporate Values</a>
                  </li>
                  <li onClick={Leadership}>
                    <a href="#" className="block py-2 hover:bg-gray-100">Leadership</a>
                    <li onClick={Management}>
                    <a href="#" className="block py-2 hover:bg-gray-100">Management</a>
                  </li>
                  </li>
                  <li onClick={OurPeople}>
                    <a href="#" className="block py-2 hover:bg-gray-100">Our People</a>
                  </li>
                  <li onClick={AboutUs}>
                    <a href="#" className="block py-2 hover:bg-gray-100">About Us</a>
                  </li>
                  
                </ul>
              </nav>
            )}
          </div>
          <div className="p-4">
            <h2 className="font-bold cursor-pointer" onClick={toggleColumn3}>Sustainable Business</h2> {/* Added onClick event */}
            {isColumn3Open && ( // Conditionally render Column 3
              <nav>
                <div className="hover:cursor-pointer  py-2 " onClick={Objectives}>
                  Corporate Objectives
                </div>
                <div className="hover:cursor-pointer py-2 " onClick={Environment}>
                  The Environment
                </div>
                <div className="hover:cursor-pointer py-2 " onClick={HSEPolicy}>
                  HSE Policy
                </div>
                <div className="hover:cursor-pointer py-2 " onClick={HRPolicy}>
                  HR Philosophy
                </div>
                <div
                  className="hover:cursor-pointer py-2"
                  onClick={LocalContent}
                >
                  Local Content
                </div>
                <div
                  className="hover:cursor-pointer py-2"
                  onClick={SocialInvestment}
                >
                  Social Investment
                </div>
                <div className="hover:cursor-pointer py-2 " onClick={Scholarship}>
                  Company Scholarship
                </div>
                <div className="hover:cursor-pointer py-2 " onClick={Mentorship}>
                  Mentorship Program
                </div>
                <div className="hover:cursor-pointer py-2 " onClick={RTI}>
                  Right To Information
                </div>
              </nav>
            )}
          </div>

          <div className="p-4" onClick={Operations}>
            <h2 className="font-bold">Operations</h2>
          </div>

          <div className="p-4" >
            <h2 className="font-bold cursor-pointer" onClick={toggleColumn4}>Investor Relations</h2> {/* Added onClick event */}
            {isColumn4Open && ( // Conditionally render Column 4
              <nav>
                <div className="hover:cursor-pointer py-2" onClick={Strategy}>
                  Strategy
                </div>
                <div className="hover:cursor-pointer py-2" onClick={Mandate}>
                  Mandate
                </div>
                <div
                  className="hover:cursor-pointer py-2"
                  onClick={KeyObjectives}
                >
                  Key Objectives
                </div>
                <div
                  className="hover:cursor-pointer py-2"
                  onClick={BestPractices}
                >
                  Best Practices
                </div>

                <div className="hover:cursor-pointer py-2" onClick={Tariff}>
                  Tariff
                </div>
              </nav>
            )}
          </div>
          <div className="p-4" onClick={Careers}>
            <h2 className="font-bold">Careers</h2>
          </div>
          <div className="p-4" onClick={Media}>
            <h2 className="font-bold">Media</h2>
          </div>
          <div className="p-4" onClick={Community}>
            <h2 className="font-bold">CSI</h2>
          </div>
          <div className="p-4" onClick={Contact}>
            <h2 className="font-bold">Contact Us</h2>
          </div>
        </div>
      </div>



      <div
        className={`fixed top-0 left-0 z-30 w-full h-full bg-black opacity-0 transform transition-opacity duration-300 ${isOpen ? 'opacity-50' : 'opacity-0 pointer-events-none'
          }`}
        onClick={toggleSidebar}
      ></div>
    </div>
  );
};

export default MobileSidebar;

