import React, { useState } from 'react';
import CEO from "./assets/ceo-picture.jpg";


const images = [
    { id: 1, src: CEO, title: 'It is our passion to build a strong Ghanaian economy through a culture of robust business development, constant human capacity building as well as supporting our project-affected communities' },
];

const App = () => {
    const [visibleImages, setVisibleImages] = useState([
        images[0],
    ]);
    const [currentIndex, setCurrentIndex] = useState(0);

    const showNextImages = () => {
        // Calculate the next set of images in a loop
        const nextIndex = (currentIndex + 1) % images.length;
        const nextImages = [
            images[nextIndex],
            images[(nextIndex + 1) % images.length],
            images[(nextIndex + 2) % images.length],
            images[(nextIndex + 3) % images.length],
        ];

        setVisibleImages(nextImages);
        setCurrentIndex(nextIndex);
    };

    const showPreviousImages = () => {
        // Calculate the previous set of images in a loop
        const previousIndex = (currentIndex - 1 + images.length) % images.length;
        const previousImages = [
            images[previousIndex],
            images[(previousIndex + 1) % images.length],
            images[(previousIndex + 2) % images.length],
            images[(previousIndex + 3) % images.length],
        ];

        setVisibleImages(previousImages);
        setCurrentIndex(previousIndex);
    };

    return (
        <div className="flex flex-col mt-3 small-laptop:mt-1 tablet:mt-8">
            <div className="flex flex-col sm:flex-row w-full md:space-x-5 px-5">
                {visibleImages.map((image) => (
                    <div key={image.id} className="flex flex-col sm:w-1/4 md:w-full mt-8">
                        <div className="relative">
                            <img src={image.src} alt={`Image ${image.id}`} className='phone:h-[300px] phone:w-full md:w-full phone:object-cover'/>
                            <h1 className='font-fira text-lg sm:text-xl absolute inset-0 text-center text-white bg-black bg-opacity-50 flex justify-center items-center px-5'>
                                {image.title}
                            </h1>
                            <h1 
                            className='font-fjalla text-lg sm:text-xl absolute inset-0 phone:pt-[220px] text-white flex justify-center items-center px-5'
                            >Dr. Ben K. D. Asante, CEO</h1>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default App;
