import React from "react";
import backgroundImage from "../assets/About us.png";
import companyLogo from "../assets/company-logo.png";

export default function Closing() {
  return (
    <div className='flex justify-center items-center h-screen w-full relative'>
      <div
        className='absolute top-0 left-0 w-full h-full'
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          opacity: 0.6,
        }}
      />

      <div className='absolute top-0 left-0 w-full h-full bg-black opacity-70' />
      <div className='relative z-10 text-white text-center'>
        <div className='justify-center w-full flex items-center mb-8'>
          <a href='https://www.ghanagas.com.gh/'>
            <img
              src={companyLogo}
              alt='Ghanagas Logo'
              className='h-28 w-48'
              target='https://www.ghanagas.com.gh/'/>
           </a>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <hr className='w-2/3 my-8' />
          <h2 className='text-5xl font-serif'>
            Sorry, the Portal is Closed. <br /> Please, Try Next Year
          </h2>
          <hr className='w-2/3 my-8' />
          <div className='flex justify-center w-full '>
            <a
              href='https://www.ghanagas.com.gh/'
              className='bg-orange-600 p-3 text-white font-semibold mt-6'>
              Visit Our Website
            </a>
          </div>
        </div>
      </div>
    </div>
  );

}