import React from 'react'
import HeaderImage from '../assets/executive.png'
import Navigation from '../navbar'
import Asmah from '../assets/leadership/rene.png'
import Footer from '../footer'
import { useNavigate } from 'react-router-dom';


function Leadership() {
    let navigate = useNavigate();

    const Management = () => {
        navigate("/robertasmah")
    }

    const ExecuManagement = () => {
        navigate("/leadership")
    }
    return (

        <>

            <Navigation />

            <div className="relative">
                <div className="absolute inset-0 bg-black bg-opacity-50 flex">
                    <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">

                    </h1>
                </div>
                <img
                    className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
                    src={HeaderImage}
                    alt="Ghana Gas GPP"
                />
            </div>

            <h1 className="font-fjalla text-gasblue text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10 phone:hidden md:block  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px] hover:cursor-pointer" onClick={ExecuManagement}>
                Executive Management
            </h1>

            <div class="grid md:grid-cols-3 phone:grid-cols-2 gap-4">
                <div class="col-span-1 flex items-start justify-end">
                    <img src={Asmah} alt="CEO Image" className='scale-75' />
                </div>
                <div class="col-span-2 flex items-center">
                    <div>
                        <p class="text-black font-fjalla text-[24px] md:mx-20 phone:mx-10">Rene Nelson </p>
                        <p class="text-black font-fira text-[20px] md:mx-20 phone:mx-10">General Manager, Accounting</p>
                        <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Rene is an accomplished accounting and finance professional with over 29 years valuable experience mostly at managerial level in a number of companies across various industry sectors.</p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        As a result of his experience, Rene has gained in-depth analytical and problem-solving skills and is proficient in treasury management, investment planning, project finance and financial modelling.</p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Rene is a Chartered Certified Accountant by profession. 
He is a Fellow of both the Association of the Chartered Certified Accountants of the UK (FCCA) and the Institute of Chartered Accountants (Ghana) (FCA). He is also a Member of the Certified Practising Accountants (Australia) (CPA).

</p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Rene holds a Post Graduate Diploma (PGDip) in Financial Strategy from the Saïd Business School, University of Oxford (St. Hugh’s College). He also holds a Master of Science degree (MSc.) in Professional Accountancy from the UCL School of Management, University of London and an Executive Master of Business Administration (MBA Finance) from the University of Ghana Business School, University of Ghana.                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </p>  <br />
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        He is currently the General Manager, Accounting of the Ghana National Gas Ltd Company, the company he joined from its inception year of 2012. He is responsible for all the activities of the Accounting Department, which includes Accounting, Taxation, and the accurate recording, production and reporting of the company’s Financial Statements to all the relevant stakeholders.</p>
                    <br/>
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Prior to his appointment as General Manager, Accounting, Rene initially held the position of Project Finance Manager during the construction phase of the Western Corridor Gas Infrastructure Project and in 2015, he progressed to the position of Senior Manager, Finance where he led the transition of the Finance department from construction into operations.
                        </p>  
                        <br/>
                        <p class="text-black font-fira md:text-[19px] phone:text-[15px] md:mx-20 phone:mx-10 text-justify leading-snug">
                        Prior to joining Ghana Gas, Rene started his career as a Senior Financial Accountant at the Great Ormond Street Children Hospital Trust in the UK. He then moved to Ghana where he joined Cal Merchant Bank (Cal Bank) as a Money Market Dealer within the Treasury department and then later on as a Manager in the Financial Controls Department. Rene has also held the position of Financial Controller at Leasafric Ghana Limited and then as Corporate Finance and Treasury Manager at GECAD Ghana Limited, where he was involved in the project financing and construction of the 125 MW Cenit Plant at Tema.
                        </p> 
                        <br />
                    </div>
                </div>
            </div>
            <br />

            <Footer />
        </>
    )

}

export default Leadership;