import React from "react";
import HeaderImage from "../assets/Profile.jpg";
import Mission from '../assets/ggmisson.jpg';
import CorporateValues from '../assets/vision.png';
import Footer from "../footer";
import Navigation from "../navbar";
import { useNavigate } from 'react-router-dom';


function Profile() {
  let navigate = useNavigate();
 
  const MV = () => {
    navigate("/mission")
  }

  const CorpV = () => {
    navigate("/corporatevalues")
  }

  return (
    <>
      <Navigation />
      <div className="relative">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
       
          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
          src={HeaderImage}
          alt="Ghana Gas GPP"
        />
      </div>
      <div>
        <h1 className="font-fjalla text-gasblue phone:p-4 text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:m-10 xl:text-5xl xl:mx-[270px] xl:mt-[100px]">
          Profile
        </h1>

        <h1 className="font-fira text-dark text-xl xs:text-[15px] xs:leading-snug xs:justify-start phone:p-4 phone:text-justify md:p-2 xs:text-justify xs:m-10  sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:text-[28px] xl:mx-[270px]">
          GNGC has a workforce of professionals and specialists in the field of
          business. The company encourages teamwork, loyalty, respect,
          integrity, accountability, excellence and professionalism and cares
          greatly about the environment.
        </h1>

        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
          The company has well defined training programmes for its officers that
          enhance their skills and capabilities, encouraging staff to remain
          committed to the values and vision of GNGC.
        </h1>

        <h1 className=" font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
          As a fledgling gas company, GNGC provides room for new ideas to boost
          its activities and subsequently, increase economic gains from the gas
          business. The officers of GNGC are therefore motivated to give their
          utmost best to achieve the set target for each commercial year.
        </h1>

        <h1 className=" font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px]  xl:leading-snug">
          In view of this, the company establishes an environment that has
          effective leadership, open lines of communication, excellent learning
          and development opportunities, competitive reward and benefits; and a
          culture that appreciates good performance.
        </h1>

        <h1 className="font-fira text-dark text-xl xs:text-[15px] phone:p-4 phone:text-justify xs:leading-snug xs:justify-start p-2 xs:text-justify xs:m-10 sm:text-[17px] sm:text-center sm:text-justify sm:mt-10 sm:ml-10 md:ml-10 md:leading-snug md:text-[25px] md:justify-start md:text-justify lg:text-[27px] lg:m-10 lg:justify-start lg:text-justify xl:justify-start xl:text-justify xl:text-[28px] xl:mx-[270px] xl:leading-snug">
          GHANA GAS, OUR PEOPLE OUR STRENGTH.
        </h1>

        <div className="relative hover:cursor-pointer" onClick={MV}>
          <div className="absolute inset-y-0 inset-x-1/2 left-0 bg-black bg-opacity-50 flex">
            <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
              Mission & Vision
            </h1>
          </div>
          <img
            className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:"
            src={Mission}
            alt="Ghana Gas GPP"
          />
        </div>

        <div className="relative hover:cursor-pointer" onClick={CorpV}>
          <div className="absolute inset-y-0 inset-x-1/2 right-0 bg-black bg-opacity-50 flex">
            <h1 className="font-fjalla text-white text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mt-30 xl:ml-30">
              Corporate Values
            </h1>
          </div>
          <img
            className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[400px] xl:w-full xl:h-[]"
            src={CorporateValues}
            alt="Ghana Gas GPP"
          />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Profile;
