import React from 'react'
import Navigation from '../navbar'
import HeaderImage from '../assets/history/firstgas.jpg';
import InitialStation from '../assets/initial-station.jpg'
import SRMS from '../assets/Sekondi Regulatory and Metering Station.jpg'
import BlockValve from '../assets/blockvalve.jpg'
import CompressorStation from '../assets/compressor-station.jpg'
import Footer from '../footer'
import Karpower from '../assets/history/karpower.jpg'
import LoadingGantry from '../assets/operations-1.png'
import EPLine from '../assets/history/epline.jpg'
import DVS from '../assets/history/pipeline.jpg'
import TDS from '../assets/Takoradi Distribution Station.jpg'
import GPP from '../assets/Gas Processing Plant.jpg'

function Facility() {
  return (
    <>
      <Navigation />
      <div className="relative">
        <div className="absolute inset-0 bg-black bg-opacity-50 flex">
          <h1 className="font-fjalla text-white text-center text-2xl xs:mt-8 xs:ml-10 sm:text-3xl sm:mt-20 sm:ml-10  md:text-4xl md:mt-20 md:ml-10 lg:text-5xl lg:mt-24 lg:m-10 xl:text-6xl xl:mx-[270px]">
            Infrastructure
          </h1>
        </div>
        <img
          className="w-full h-auto xs:w-full xs:h-[200px] xs:object-cover sm:w-full sm:h-[300px] md:w-full md:h-[400px] md:object-cover lg:w-full lg:h-[500px] xl:w-full xl:"
          src={HeaderImage}
          alt="Ghana Gas GPP"
        />
      </div>


      <div className="">
        {/* First Grid */}
       <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-4 mt-[10rem] md:ml-[120px] phone:space-x-4">
        <div className='flex md:flex-row'>
            <div className='flex flex-col'>
              <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[0.1rem] md:space-x-10 phone:space-y-3 md:space-y-0 phone:p-5'>
                <div class="relative">
                  <img src={InitialStation} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover " />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px]">Initial Station</p>
                    </div>
                  </div>
                </div>

                <div class="relative">
                  <img src={CompressorStation} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px] text-center px-2">Compressor Station</p>
                    </div>
                  </div>
                </div>


              </div>
              <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                <div class="relative">
                  <img src={GPP} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px] text-center px-2">Processing Plant</p>
                    </div>
                  </div>
                </div>


                <div class="relative">
                  <img src={EPLine} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px] px-2 text-center">Esiama-Prestea Transmission Lateral Pipeline</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                <div class="relative">
                  <img src={LoadingGantry} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px] text-center px-2">Loading Gantry</p>
                    </div>
                  </div>
                </div>


                <div class="relative">
                  <img src={TDS} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px] text-center px-2">Atuabo-Takoradi (AT) Onshore Transmission Pipeline</p>
                    </div>
                  </div>
                </div>
              </div>


              <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                <div class="relative">
                  <img src={Karpower} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px] text-center px-2">Karpowership</p>
                    </div>
                  </div>
                </div>


                <div class="relative">
                  <img src={BlockValve} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px] text-center px-2">Block Valve Stations (BLVs)</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='flex md:flex-row phone:flex-col phone:pt-[5rem] md:pt-[10rem] md:space-x-10 phone:space-y-3 phone:p-5 md:space-y-0'>
                <div class="relative">
                  <img src={DVS} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px] text-center px-2">Distribution Valve Stations (DVS)</p>
                    </div>
                  </div>
                </div>


                <div class="relative">
                  <img src={SRMS} alt="Your Image" class="xl:h-[434px] xl:w-[434px] object-cover" />
                  <div class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className='flex flex-col'>
                      <p class="text-white font-fjalla md:text-[35px] md:leading-10 phone:text-[25px] text-center px-2">Sekondi Regulating and Metering Station</p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>



          <span className='md:bg-black md:h-full phone:bg-white md:w-[546px] md:ml-[12rem] phone:ml-[30px] phone:w-[400px]'>
                        <h1 className='md:text-white phone:text-black font-fira p-6 md:text-[12px] phone:text-[18px] lg:text-[15px] xl:text-[19px] text-justify'>The Ghana National Gas Company Infrastructure comprises:<br /><br />
              <b>Offshore Gas Pipeline</b><br />
              A 58km, 12-inch diameter offshore pipeline, transporting dense-phase raw gas from Jubilee and TEN fields to the Gas Processing Plant (GPP) at Atuabo in the Western Region. This 12-inch x 58km offshore subsea pipeline has two segments; 14km deep water segment and 44km shallow water segment.
              <br /><br />

             <b> Gas Processing Plant (GPP)</b><br />
              A Gas Processing Plant (GPP) at Atuabo with a design capacity of 150MMscfd and normal operating capacity of 120MMscfd. First gas was on 10th November, 2014 and commercial operations commenced in April 2015. The two main functions of GPP is full processing of raw gas to produce pipeline quality lean/sales gas and to produce LPG, pentanes and stabilized condensates. The lean/sales gas is primarily methane and ethane (ethane is not recovered at the plant). The objectives of the gas processing module are:<br/>
              • to meet the hydrocarbon dew point specification; pipeline transport requires that there be no condensation of the gas at the operating conditions of the pipeline. Condensation leads to liquids causing higher pressure drops, slugging, and interferes with gas metering and recompression stations and reduction in pipeline cross-sectional area.
             <br/> • To meet the sales gas maximum heavy hydrocarbon specification of heating value.
              <br/>• and also to maximize natural gas liquid recovery (for economic reasons) that is– Liquefied Petroleum Gas (LPG), Pentanes and stabilized Condensates.<br/><br/>
              Some of the facilities/technologies for the raw gas processing at the GPP include reception facilities/ inlet separators and filters, hydrate inhibitors and hydrocarbon dew point control dethanizer, debutanizer and pentane stripper. There are 4 LPG spherical storage tanks and 2 condensate storage tanks.
              <br /><br />
              <b>Loading Gantry</b><br />
              A loading gantry located approximately 2.5km from the GPP for evacuating Liquefied Petroleum Gas (LPG) and condensates. The Loading Gantry consists of a gantry structure with arms that extend over the road tankers to deliver the LPG. The gantry is equipped with meters and other instrumentation to accurately measure the quantity of LPG being loaded, and to monitor the loading process to ensure it is safe and efficient.
              <br /><br />
             <b> Atuabo-Takoradi (AT) Onshore Transmission Pipeline </b><br />
              The Atuabo-Takoradi (AT) Onshore Transmission Pipeline is a 20-inch diameter, 110km gas transmission pipeline that transports the lean sales gas from Atuabo to Power plants in the Western Power Enclave and industries in Takoradi. The pipeline, with a design capacity of 405 MMscfd at 100 barg, was completed in 2013. The Atuabo-Takoradi onshore Transmission Pipeline has the following associated facilities:<br/>
              • <b>Atuabo Initial Station (AIS)</b>: The AIS is the first station of the Onshore Pipeline system. AIS is where the sales gas is injected into the export pipeline. The lean gas leaving the plant flows into a gas filter and separator unit where any liquid entrained is removed. The collected liquid is piped to a drain drum while the separated and filtered gas is metered and exported. The sales gas specification is checked using an on-line gas chromatograph. The Main Pipeline system is remotely monitored 24/7 by operators at the AIS control room.<br />
              • <b>Anokyi Mainline Compressor Station</b>: The Anokyi Mailine Compressor Station has been constructed to boost the sales gas pressure prior to exporting to the GNGC onshore pipeline network. The Phase 1 of the project has been completed and includes the installation of two (2) reciprocating compressor units with a total design capacity of 180 MMscf/D.<br />
              • <b>ENI Ghana Onshore Receiving Facility (ORF) at Sanzule</b>: The Eni Ghana operated FPSO John Agyekum Kufuor exports processed gas through a gas export pipeline to the ORF at Sanzule. The gas comingles with gas from GPP to be exported through the main AT pipeline.<br />
              • <b>Essiama Distribution Station (EDS)</b>: EDS was constructed to allow transport of gas northwards to Prestea and its enclave.<br />
              • <b>2 block valve stations</b>: These are used to isolate segments of the pipeline for maintenance work or isolate a rupture or leak.<br />
              • <b>Takoradi Distribution Station (TDS)</b>: The Takoradi Distribution Station, formerly, the Takoradi Regulating and Metering Station was expanded to its current capacity to handle flows up to 405 MMScf/D. The station serves as the major distribution point for the various power plants in the western enclave. TDS is also connected to other Distribution Valve Stations (DVS) for further distribution to industrial users
              <br /><br />
              <b>Esiama-Prestea Transmission Lateral Pipeline</b><br />
              The Esiama-Prestea (EP) Lateral Pipeline is a 20-inch diameter, 75km onshore gas transmission pipeline that transports lean/sales gas from Esiama Distribution Station (EDS) to the Prestea Regulation and Metering Station (PRMS) for the northwards inland distribution of the gas.
              <br/>The pipeline was commissioned in 2016 and started commercial operations in 2017.
              <br/>The Esiama-Prestea (EP) Lateral Pipeline has the following associated stations<br/>
              •<b>Esiama Distribution Station (EDS)</b><br/>
              •<b>Prestea Regulating & Metering Station (PRMS)</b>: The PRMS was constructed to aid the extension of the gas infrastructure network further in-country along the western corridor and transport natural gas to serve potential consumers in the mining enclave. The station receives gas through the 75km 20-inch lateral pipeline from the EDS. With a capacity of 150 MMscfd, PRMS serves as the supply point for gas to GENSER Energy who has distribution stations in Tarkwa, Damang and Wassa
              <br/>•<b>Block Valve Stations (BLVS)</b>: these are used to isolate segments of the pipeline for maintenance work or isolate a rupture or leak
              <br /><br />
             <b> Karpowership and Sekondi Regulating and Metering Station</b><br />
              The Karpower Pipeline is a 10km pipeline from the TDS to the Karpower Ship at the Sekondi Naval. Other facilities associated with the Karpower pipeline are the Distribution Valve Station (DVS 2) and Sekondi Regulating and Metering Station (SRMS). The DVS 2 serves at a supply point to supply lean gas to the Twin City Power Plant. The SRMS was constructed and commissioned as part of the infrastructure to supply lean gas to the Karpowership. The station receives its feed gas from the Takoradi Distribution Station (TDS) through a 20-inch 8km onshore pipeline and supplies it to the Karpowership through a 26-inch 2km offshore pipeline
            </h1>
          </span>

        </div>
      </div>
      <div className='mt-10'>
        <Footer />
      </div>

    </>
  )
}

export default Facility;
